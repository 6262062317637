import { debounce } from '../helper/functions';

class Search extends HTMLElement {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      query: ''
    };
    this.dom = {
      form: document.querySelector('.js-search-form'),
      bar: document.querySelector('.js-search-form-bar'),
      search: document.querySelector('.js-search-input'),
      close: document.querySelectorAll('.js-close-search'),
      header: document.querySelector('header-wrapper'),
      overlay: document.querySelector('#search-overlay')
    };
    this.addEventListener('click', this.toggle.bind(this));
    this.dom.close.forEach(element => {
      element.addEventListener('click', this.toggle.bind(this));
    })
    this.dom.search.addEventListener(
      'input',
      debounce(() => this.handleSearch.bind(this), 250)
    );
    this.dom.form.addEventListener('submit', this.handleSubmit.bind(this));
  }
  toggle() {
    const { state, dom } = this;

    if (state.isOpen) {
      dom.bar.classList.remove('top-[var(--header-height)]');
      dom.bar.classList.add('-top-[var(--header-height)]');
      dom.overlay.classList.add('opacity-0');
      dom.overlay.classList.remove('opacity-40');
      dom.overlay.classList.add('pointer-events-none');
      dom.search.blur();
    } else {
      dom.bar.classList.remove('-top-[var(--header-height)]');
      dom.bar.classList.add('top-[var(--header-height)]');
      dom.overlay.classList.add('opacity-40');
      dom.overlay.classList.remove('opacity-0');
      dom.overlay.classList.remove('pointer-events-none');
      dom.search.focus();
    }

    state.isOpen = !state.isOpen;
    state.query = '';
    dom.search.value = state.query;
  }
  handleSearch() {
    const { state, dom } = this;
    const { search } = dom;
    const searchTerm = search.value.trim();

    return (state.query = searchTerm);
  }
  handleSubmit(e) {
    e.preventDefault();

    const { state, dom } = this;
    state.query = `/search?q=${state.query}`;
    dom.form.action = state.query;

    return dom.form.submit();
  }
}

if (!window.customElements.get('search-toggle')) {
  window.customElements.define('search-toggle', Search);
}

class SearchWrapper extends HTMLElement {
  constructor() {
    super();

    this.url = document.querySelector('#search_url').value;

    this.grids = {
      pageGrid: this.querySelector('#page-grid'),
      productGrid: this.querySelector('#product-grid'),
      articleGrid: this.querySelector('#article-grid')
    };

    this.toggles = {
      pageToggle: this.querySelector('#page-toggle'),
      productToggle: this.querySelector('#product-toggle'),
      articleToggle: this.querySelector('#article-toggle')
    };

    this.loadMoreButtons = {
      pageLoadMoreButton: this.querySelector('#page-load-more-button'),
      productLoadMoreButton: this.querySelector('#product-load-more-button'),
      articleLoadMoreButton: this.querySelector('#article-load-more-button')
    };

    Object.keys(this.grids).forEach((gridKey) => {
      if (this.grids[gridKey] !== null) {
        //handles click of the swap between each type of result
        const url = this.grids[gridKey].dataset.url;
        const type = this.grids[gridKey].id.replace('-grid', '');

        //fetches the first page of products

        this.fetchTypeResults(url, 1, type);
      }
    });

    Object.keys(this.toggles).forEach((toggleKey) => {
      if (this.toggles[toggleKey] !== null) {
        //handles click of the swap between each type of result
        this.toggles[toggleKey].addEventListener(
          'click',
          this.handleToggleClick.bind(this, toggleKey)
        );

        //fetches the first page of products
        const url = this.toggles[toggleKey].querySelector('input').value;
        const type = this.toggles[toggleKey].id.replace('-toggle', '');
      }
    });

    Object.keys(this.loadMoreButtons).forEach((loadMoreKey) => {
      if (this.loadMoreButtons[loadMoreKey] !== null) {
        const url = this.loadMoreButtons[loadMoreKey].dataset.url;
        const type = this.loadMoreButtons[loadMoreKey].id.replace(
          '-load-more-button',
          ''
        );
        //handles click of the swap between each type of load more
        this.loadMoreButtons[loadMoreKey].addEventListener(
          'click',
          this.handleLoadMoreClick.bind(this, url, type, loadMoreKey)
        );
      }
    });
  }

  connectedCallback() {
    this.fetchAllResults(this.url);
  }

  handleToggleClick(toggleKey) {
    // First, remove the class from all grids
    Object.keys(this.grids).forEach((gridKey) => {
      if (this.grids[gridKey] !== null) {
        this.grids[gridKey].parentElement.classList.add('hidden');
      }
    });

    //remove toggle underline from all toggles
    Object.keys(this.toggles).forEach((toggleKey) => {
      if (this.toggles[toggleKey] !== null) {
        this.toggles[toggleKey].querySelector('div').classList.add('hidden');
      }
    });

    // Add the class to the matching grid
    this.grids[
      toggleKey.replace('Toggle', 'Grid')
    ].parentElement.classList.remove('hidden');

    // Add the class to the clicked toggle
    this.toggles[toggleKey].querySelector('div').classList.remove('hidden');
  }

  handleLoadMoreClick(url, type, loadMoreKey) {
    const page = this.loadMoreButtons[loadMoreKey].dataset.page;
    this.fetchTypeResults(url, page, type);
  }

  fetchAllResults(url, page = 1, allResults = []) {
    const searchUrl = url + `&page=${page}` + `&type=product,article`;
    fetch(searchUrl)
      .then((response) => response.text())
      .then((data) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, 'text/html');
        const results = Array.from(doc.querySelectorAll('.search-result-item'));

        if (results.length) {
          allResults = allResults.concat(results);
          this.fetchAllResults(url, page + 1, allResults);
        } else {
          // Count types
          let productCount = 0,
            articleCount = 0,
            pageCount = 0;

          allResults.forEach((item) => {
            if (item.classList.contains('product')) productCount++;
            else if (item.classList.contains('article')) articleCount++;
            else if (item.classList.contains('page')) pageCount++;
          });

          //Display products in grid

          // Display counts
          if (this.querySelector('#product-toggle p')) {
            this.querySelector('#product-toggle p').textContent =
              `products\xA0(${productCount})`;
            this.querySelector('#product-toggle').dataset.count = productCount;
          }

          if (this.querySelector('#article-toggle p')) {
            this.querySelector('#article-toggle p').textContent =
              `the archive\xA0(${articleCount})`;
            this.querySelector('#article-toggle').dataset.count = articleCount;
          }

          if (this.querySelector('#page-toggle p')) {
            this.querySelector('#page-toggle p').textContent =
              `pages\xA0(${pageCount})`;
            this.querySelector('#page-toggle').dataset.count = pageCount;
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching search results:', error);
      });
  }

  fetchTypeResults(url, page, type) {
    const search_url = url + `&page=${page}` + `&type=${type}`;
    fetch(search_url)
      .then((response) => response.text())
      .then((data) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, 'text/html');
        const results = Array.from(doc.querySelectorAll('.search-result-item'));

        const grid = this.querySelector(`#${type}-grid`);
        const loadMore = this.querySelector(`#${type}-load-more-button`);

        loadMore.dataset.page++;

        if (page > 1) {
          results.forEach((item) => {
            grid.appendChild(item);
          });
        } else {
          grid.innerHTML = doc.querySelector(`#${type}-grid`).innerHTML;
        }

        if (
          grid.childElementCount >=
          this.querySelector(`#${type}-toggle`).dataset.count
        ) {
          loadMore.classList.add('!hidden');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

if (!window.customElements.get('search-wrapper')) {
  window.customElements.define('search-wrapper', SearchWrapper);
}
