class ProductEditorialGalleries extends HTMLElement {
  constructor() {
    super();
    this.$dom = {
      buttons: {
        beforeAfter: this.querySelector(".js-button-before-after"),
        styleGallery: this.querySelector(".js-button-style-gallery"),
      },
      sections: {
        beforeAfter: this.querySelector(".js-section-before-after"),
        styleGallery: this.querySelector(".js-section-style-gallery"),
      }
    };
    this.state = {
      activeClass: "is-active",
      activeButton: null,
      activeSection: null
    };
  }

  connectedCallback() {
    if (this.$dom.buttons.beforeAfter) {
      this.$dom.buttons.beforeAfter.addEventListener("click", () => this.setActive('beforeAfter'));
    }
    if (this.$dom.buttons.styleGallery) {
      this.$dom.buttons.styleGallery.addEventListener("click", () => this.setActive('styleGallery'));
    }
    this.setInitialState();
  }

  setInitialState() {
    const { buttons } = this.$dom;
    const { activeClass } = this.state;
    const activeButton = Object.values(buttons).find(button => button?.classList.contains(activeClass)) || buttons.beforeAfter;
    const activeSection = activeButton === buttons.beforeAfter ? 'beforeAfter' : 'styleGallery';
    this.setActive(activeSection);
  }

  setActive(type) {
    const { buttons, sections } = this.$dom;
    const { activeClass } = this.state;

    // Deactivate current active elements
    if (this.state.activeButton) {
      this.state.activeButton.classList.remove(activeClass);
      this.state.activeButton.setAttribute('aria-selected', 'false');
    }
    if (this.state.activeSection) {
      this.state.activeSection.classList.remove(activeClass);
      this.state.activeSection.setAttribute('aria-active', 'false');
    }

    // Activate selected elements
    const newActiveButton = buttons[type];
    const newActiveSection = sections[type];

    if (newActiveButton && newActiveSection) {
      newActiveButton.classList.add(activeClass);
      newActiveButton.setAttribute('aria-selected', 'true');
      newActiveSection.classList.add(activeClass);
      newActiveSection.setAttribute('aria-active', 'true');

      // Update state
      this.state.activeButton = newActiveButton;
      this.state.activeSection = newActiveSection;

      window.dispatchEvent(new Event('resize'));
    }
  }
}

if (!window.customElements.get("product-editorial-galleries")) {
  window.customElements.define("product-editorial-galleries", ProductEditorialGalleries);
}
