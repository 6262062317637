import { fetchConfig } from '../helper/fetch-config';
import { publish } from '../helper/pubsub';
import { PUB_SUB_EVENTS } from '../helper/constants';

/* Variant Select

    - when a variant is selected grab all product variant values
    - with those values generate a URL that will pull the entire PDP
    - with the new PDP re-render the HTML needed
        - Product Media & Info always needs re-render & recharge re initialize
        - Product Editorial will need to render conditionally. 
            -- if metaobject we can see the if the metaobject ID differs.
            -- if metafield we can see if the section exists or not, content difference will be too difficult.

*/
class VariantSelect extends HTMLElement {
  constructor() {
    super();
    this.productForm = this.closest('product-form');
    this.productVariants = this.querySelectorAll('product-variant');

    this.selectedVariantOption = this.productForm.querySelectorAll(
      'input[name="selected-variant-option"]'
    );
    this.selectedVariantId = this.productForm.querySelectorAll(
      'input[name="selected-variant-id"]'
    );

    this.globalProductId = this.productForm.querySelector(
      'input[name="product-id"]'
    ).value;
  }

  connectedCallback() {
    this.applyEventListeners();
  }

  applyEventListeners() {
    [...this.productVariants].forEach((variant) => {
      variant.addEventListener('click', this.onVariantChange.bind(this));
    });
  }

  async renderProductInformation(targetValue) {
    /*this part of the code assumes URLS are formatted as the following
    /products/[product-title]-[product-size]:
    EG: new-wash-original-8-oz;
    */
    try {
      const response = await fetch(targetValue);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      if (window.location.pathname.includes('/products')) {
        history.pushState({}, '', targetValue);
      }

      const data = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, 'text/html');

      const updatedMedia = doc.querySelector('product-media');
      const updatedTitle = doc.querySelector('#productTitle');
      const updatedForm = doc.querySelector('#product-atc');
      const updatedInfo = doc.querySelector('tab-group');
      const updatedProductRecs = doc.querySelector('recommended-products');
      const updatedProductId = doc.querySelector('input[name="product-id"]');
      const updatedBreadCrumb = doc.querySelector('.breadcrumbs');
      const updatedIngredientsSlider = doc.querySelector('#content-carousel');
      const updatedFAQs = doc.querySelector('#editorial-faq');
      const updatedEditorialGalleries = doc.querySelector('#product-editorial-galleries');

      if (updatedProductId) {
        const currentProductId = this.productForm.querySelector('input[name="product-id"]');
        if (currentProductId && currentProductId.value !== updatedProductId.value) {
          const reviewWidget = document.querySelector('.yotpo-widget-instance[data-yotpo-instance-id="476744"]');
          
          if (reviewWidget) {
            const updatedProductData = {
              title: doc.querySelector('h1')?.textContent?.trim(),
              url: window.location.pathname,
              price: doc.querySelector('[data-product-price]')?.getAttribute('data-product-price'),
              image: doc.querySelector('product-media img')?.src,
              description: doc.querySelector('meta[name="description"]')?.content,
              id: updatedProductId.value
            };
      
            const newWidget = document.createElement('div');
            newWidget.className = 'yotpo-widget-instance';
            newWidget.setAttribute('data-yotpo-instance-id', '476744');
            newWidget.setAttribute('data-yotpo-product-id', updatedProductData.id);
            newWidget.setAttribute('data-yotpo-name', updatedProductData.title || '');
            newWidget.setAttribute('data-yotpo-url', window.location.origin + updatedProductData.url);
            newWidget.setAttribute('data-yotpo-image-url', updatedProductData.image || '');
            newWidget.setAttribute('data-yotpo-price', updatedProductData.price || '');
            newWidget.setAttribute('data-yotpo-currency', reviewWidget.getAttribute('data-yotpo-currency')); // Keep existing currency
            newWidget.setAttribute('data-yotpo-description', updatedProductData.description || '');
      
            const widgetParent = reviewWidget.parentNode;
            reviewWidget.remove();
      
            widgetParent.appendChild(newWidget);
      
            yotpoWidgetsContainer.initWidgets()
          } else {
            console.log('Review widget not found');
          }
        }
      }

      if (updatedIngredientsSlider) {
        const currentIngredientsSlider = document.querySelector('#content-carousel');
        if (currentIngredientsSlider) {
          currentIngredientsSlider.innerHTML = updatedIngredientsSlider.innerHTML;
        }
      }
      if (updatedFAQs) {
        const currentFAQs = document.querySelector('#editorial-faq');
        if (currentFAQs) {
          currentFAQs.innerHTML = updatedFAQs.innerHTML;
        }
      }

      if (updatedEditorialGalleries) {
        const currentEditorialGalleries = document.querySelector('#product-editorial-galleries');
        if (currentEditorialGalleries) {
          currentEditorialGalleries.innerHTML = updatedEditorialGalleries.innerHTML;
          const newGalleries = currentEditorialGalleries.querySelector('product-editorial-galleries');
          if (newGalleries) {
            customElements.upgrade(newGalleries);
          }
        }
      }

      if (updatedBreadCrumb) {
        const currentBreadcrumb = document.querySelector('.breadcrumbs');
        if (currentBreadcrumb) {
          currentBreadcrumb.innerHTML = updatedBreadCrumb.innerHTML;
        }
      }

      if (updatedMedia) {
        const currentSection = document.querySelector('product-media');
        const upgradedMedia = document.querySelector('product-media');
        if(currentSection.getAttribute('source') == "featured-product"){
          const updatedImg = updatedMedia.querySelector('img');
          updatedImg.classList.remove('aspect-square', 'object-container');
          updatedImg.classList.add('aspect-[5/7]', 'object-cover');
          currentSection.querySelector('img').replaceWith(updatedImg);
        } else {
          currentSection.replaceWith(updatedMedia);
          customElements.upgrade(upgradedMedia);
          upgradedMedia.generateCarousel();
        }
      
      } else {
        console.error('Updated section not found in the response');
      }

      if (updatedTitle) {
        const currentTitle = document.querySelector('#productTitle');
        currentTitle.innerHTML = updatedTitle.innerHTML;
      }

      if (updatedForm) {
        const currentForm = document.querySelector('#product-atc');
        if (!currentForm.querySelector('sticky-atc')) {
          updatedForm.querySelector('sticky-atc').remove();
        }

        if (
          this.productForm.querySelector('input[name="product-id"]').value ===
          updatedProductId.value
        ) {
          // If the same product ID is clicked, the select options and the subscribe and save button price are dropped
          updatedForm
            .querySelector('.delivery-options')
            .replaceWith(currentForm.querySelector('.delivery-options'));

          updatedForm
            .querySelector(`[data-price-id="price-${updatedProductId.value}"]`)
            .replaceWith(
              currentForm.querySelector(
                `[data-price-id="price-${updatedProductId.value}"]`
              )
            );
        }

        currentForm.innerHTML = updatedForm.innerHTML;
      }

      if (updatedInfo) {
        const currentInfo = document.querySelector('tab-group');
        if (currentInfo) {
          currentInfo.replaceWith(updatedInfo);
          const upgradedInfo = document.querySelector('tab-group');
          customElements.upgrade(upgradedInfo);
          //upgradedInfo.openFirstDetails();
        }
      }

      if (updatedProductRecs) {
        const currentProductRecs = document.querySelector(
          'recommended-products'
        );
        if (currentProductRecs) {
          currentProductRecs.innerHTML = updatedProductRecs.innerHTML;
        }
      }

      if (updatedProductId) {
        const currentProductId = this.productForm.querySelector(
          'input[name="product-id"]'
        );

        let shouldRemountRechargeWidget =
          currentProductId.value !== updatedProductId.value;

        if (shouldRemountRechargeWidget && window.HairstoryRechargeWidget) {
          window.HairstoryRechargeWidget[
            currentProductId.value
          ].onNewProductSelect(updatedProductId.value);
        }

        if (currentProductId) {
          currentProductId.value = updatedProductId.value;
        }
      }
    } catch (error) {
      console.error('Error fetching & updated section:', error);
    }
  }

  onVariantChange(event) {
    event.preventDefault();
    let isVariantType = false;
    [...this.productVariants].forEach((variant) => {
      if (variant.classList.contains('variant-selected-type')) {
        isVariantType = true;
      }
    });
    if (isVariantType) {
      [...this.productVariants].forEach((variant) =>
        variant.classList.remove('variant-selected-type')
      );
      event.target.classList.add('variant-selected-type');
    } else {
      [...this.productVariants].forEach((variant) =>
        variant.classList.remove('variant-selected-size')
      );
      event.target.classList.add('variant-selected-size');
    }

    //variant-value is the main target for the value of the product options.
    //variant-id returns the variant ID of the selected size product & the product ID of the product type;
    let targetValue = event.target.getAttribute('variant-value');
    let targetId = event.target.getAttribute('variant-id');

    this.selectedVariantOption.forEach((option) => {
      option.value = targetValue;
    });
    this.selectedVariantId.forEach((option) => {
      option.value = targetId;
    });

    let targetSize = this.productForm.querySelector('#selected-size').value;
    let match = targetSize.match(/(\d+-oz|\d+oz|\d+ml|d+-ml|\d+pack|d+-pack)/);
    let sizeToReplace = match ? match[1] : null;

    if (sizeToReplace) {
      // Replace the `xx-oz` part in the second URL
      targetValue = targetValue.replace(
        /(\d+-oz|\d+oz|\d+ml|d+-ml|\d+pack|d+-pack)/,
        sizeToReplace
      );
    } else {
      console.log('No size found in the first URL to replace.');
    }

    this.renderProductInformation(targetValue);
  }
}

if (!window.customElements.get('variant-select')) {
  window.customElements.define('variant-select', VariantSelect);
}

class VariantSelectCard extends VariantSelect {
  constructor() {
    super();

    this.productForm = this.closest('product-form');
    this.productVariants = this.querySelectorAll('product-variant');
    this.selectedVariantOption = this.querySelector(
      'input[name="selected-variant-option"]'
    );
    this.selectedVariantId = this.querySelector(
      'input[name="selected-variant-id"]'
    );
    this.variantIdInput = this.productForm.querySelector('input[name="id"]');
    this.priceElements = null;
    this.productIdInputs = null;
  }

  connectedCallback() {
    this.applyEventListeners();
  }

  applyEventListeners() {
    this.productVariants.forEach((variant) => {
      variant.addEventListener('click', this.onVariantChange.bind(this));
    });
  }

  async renderProductInformation() {
    this.variantIdInput.value = this.selectedVariantId.value;
  }

  onVariantChange(event) {
    event.preventDefault();

    this.productVariants.forEach((variant) =>
      variant.classList.remove('variant-selected-size')
    );
    event.target.classList.add('variant-selected-size');

    let targetProductHandle = event.target.getAttribute('variant-value');
    let targetVariantId = event.target.getAttribute('variant-id');

    this.selectedVariantOption.value = targetProductHandle;
    this.selectedVariantId.value = targetVariantId;
    this.variantIdInput.value = targetVariantId;

    this.fetchVariantData(targetProductHandle, targetVariantId);
  }

  async fetchVariantData(productHandle, variantId) {
    try {
      const previousProductId = this.productForm.querySelector(
        'input[name="product-id"]'
      ).value;
      const response = await fetch(`${productHandle.split('?')[0]}.js`);

      if (!response.ok) throw new Error('Network response was not ok');

      const productData = await response.json();

      const selectedVariant = productData.variants.find(
        (variant) => variant.id.toString() === this.selectedVariantId.value
      );

      const newProductId = String(productData.id);

      if (selectedVariant) {
        // Immutably update productIdInputs
        this.productIdInputs = Array.from(
          document.querySelectorAll('input[name="product-id"]')
        );

        // Immutably update priceElements
        this.priceElements = Array.from(
          document.querySelectorAll(
            `[data-price-id="price-${previousProductId}"]`
          )
        );

        if (this.priceElements.length > 0) {
          // Immutably update priceElements
          this.priceElements = this.priceElements.map((priceElement) => {
            const clonedElement = priceElement.cloneNode(true);
            clonedElement.setAttribute(
              'data-price-id',
              `price-${newProductId}`
            );

            let priceText = document.createElement('span');
            let compareText = document.createElement('span');
            compareText.classList.add('line-through', 'text-brand-secondary-350');

            priceText.textContent = this.formatMoney(selectedVariant.price);

            if(selectedVariant.compare_at_price) {
              const compareAtPrice = this.formatMoney(selectedVariant.compare_at_price);
              compareText.textContent =  `${compareAtPrice}`
              priceText.prepend("\u00A0")
              priceText.prepend(compareText);
            }

            clonedElement.textContent = "";
            clonedElement.append(priceText)
            return clonedElement;
          });

          // Replace old elements with new ones in the DOM
          this.priceElements.forEach((newElement, index) => {
            const oldElement = document.querySelector(
              `[data-price-id="price-${previousProductId}"]`
            );
            if (oldElement) {
              oldElement.parentNode.replaceChild(newElement, oldElement);
            }
          });
        }

        // If the user clicked on the same variant, we don't want to remount the options container
        if (newProductId !== previousProductId) {
          let deliveryOptionsContainer = this.productForm.querySelector(
            `#delivery-options-${previousProductId}`
          );
          let cardAtcContainer = document.querySelector(
            `#card-atc-${previousProductId}`
          );

          if (deliveryOptionsContainer) {
            deliveryOptionsContainer.id = `delivery-options-${newProductId}`;
            deliveryOptionsContainer.innerHTML = '';
          }

          if (cardAtcContainer) {
            cardAtcContainer.id = `card-atc-${newProductId}`;
          }
        }

        if (previousProductId && previousProductId !== newProductId) {
          let shouldRemountRechargeWidget = true;
          if (shouldRemountRechargeWidget && window.HairstoryRechargeWidget) {
            window.HairstoryRechargeWidget[
              previousProductId
            ].onNewProductSelect(newProductId);
          }
          if (this.productIdInputs.length > 0) {
            // Immutably update productIdInputs
            this.productIdInputs = this.productIdInputs.map((input) => {
              const clonedInput = input.cloneNode(true);
              clonedInput.value = newProductId;
              return clonedInput;
            });

            // Replace old inputs with new ones in the DOM
            this.productIdInputs.forEach((newInput) => {
              const previousInput = document.querySelector(
                'input[name="product-id"]'
              );
              if (previousInput) {
                previousInput.parentNode.replaceChild(newInput, previousInput);
              }
            });
          }
        }

        this.variantIdInput.value = variantId;
        this.selectedVariantId.value = variantId;

        const productFormIdInput = this.productForm.querySelector(
          'input[name="product-id"]'
        );

        if (productFormIdInput) {
          productFormIdInput.value = newProductId;
        }
      }
    } catch (error) {
      console.error('Error fetching variant data:', error);
    }
  }

  formatMoney(cents) {
    return '$' + (cents / 100).toFixed(2);
  }
}

if (!window.customElements.get('variant-select-card')) {
  window.customElements.define('variant-select-card', VariantSelectCard);
}

class VariantSelectSection extends VariantSelect {
  constructor() {
    super();
  }
}

if (!window.customElements.get('variant-select-section')) {
  window.customElements.define('variant-select-section', VariantSelectSection);
}

class ProductVariant extends HTMLElement {
  constructor() {
    super();
  }
}

if (!window.customElements.get('product-variant')) {
  window.customElements.define('product-variant', ProductVariant);
}

class ProductForm extends HTMLElement {
  constructor() {
    super();
    this.form = this.querySelector('form');
    this.cart = document.querySelector('cart-drawer');
  }

  connectedCallback() {
    this.form.addEventListener('submit', this.onSubmitHandler.bind(this));
  }

  onSubmitHandler(event) {
    event.preventDefault();
    // if (this.submitButton.getAttribute('aria-disabled') === 'true') return;

    this.handleErrorMessage();

    const config = fetchConfig('javascript');
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    delete config.headers['Content-Type'];

    const id = parseInt(this.form.querySelector('input[name="id"]').value);
    let selling_plan;

    if (this.form.querySelector('select[name="selling_plan"]')) {
      selling_plan = parseInt(
        this.form.querySelector('select[name="selling_plan"]').value
      );
    }

    let formData = {
      items: [
        {
          id,
          quantity: 1,
          ...(selling_plan && { selling_plan })
        }
      ],
      sections: this.cart.getSectionsToRender().map((section) => section.id)
    };

    this.cart.setActiveElement(document.activeElement);

    config.method = 'POST';
    config.headers = {
      'Content-Type': 'application/json'
    };
    config.body = JSON.stringify(formData);

    fetch(window.Shopify.routes.root + 'cart/add.js', config)
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          publish(PUB_SUB_EVENTS.cartError, {
            source: 'product-form',
            productVariantId: formData.items[0].id,
            errors: response.errors || response.description,
            message: response.message
          });
          this.handleErrorMessage(response.description);

          const soldOutMessage =
            this.submitButton.querySelector('.sold-out-message');
          if (!soldOutMessage) return;
          this.submitButton.setAttribute('aria-disabled', true);
          this.submitButtonText.classList.add('hidden');
          soldOutMessage.classList.remove('hidden');
          this.error = true;
          return;
        } else if (!this.cart) {
          window.location = window.routes.cart_url;
          return;
        }

        if (!this.error)
          publish(PUB_SUB_EVENTS.cartUpdate, {
            source: 'product-form',
            productVariantId: formData.items[0].id,
            cartData: response
          });
        this.error = false;
        this.cart.renderContents(response);
        this.cart.drawerOpenAnimation(this.cart.querySelector('details'));
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        if (this.cart && this.cart.classList.contains('is-empty'))
          this.cart.classList.remove('is-empty');
      });
  }

  handleErrorMessage(errorMessage = false) {
    if (this.hideErrors) return;

    this.errorMessageWrapper =
      this.errorMessageWrapper ||
      this.querySelector('.product-form__error-message-wrapper');
    if (!this.errorMessageWrapper) return;
    this.errorMessage =
      this.errorMessage ||
      this.errorMessageWrapper.querySelector('.product-form__error-message');

    this.errorMessageWrapper.toggleAttribute('hidden', !errorMessage);

    if (errorMessage) {
      this.errorMessage.textContent = errorMessage;
    }
  }
}

if (!window.customElements.get('product-form')) {
  window.customElements.define('product-form', ProductForm);
}

class StickyATC extends HTMLElement {
  constructor() {
    super();
    this.addToBagButton =
      this.closest('#product-atc').querySelector('button[name="add"]');
    this.headerHeight = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--header-height');
  }

  connectedCallback() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            this.classList.add('lg:top-[var(--header-height)]');
            this.classList.add('lg:bottom-auto');
            this.classList.remove('lg:bottom-0');
          } else {
            this.classList.remove('lg:top-[var(--header-height)]');
            this.classList.add('lg:bottom-0');
            this.classList.remove('lg:bottom-auto');
          }
        });
      },
      {
        root: null,
        rootMargin: `-${this.headerHeight} 0px 0px 0px`,
        threshold: 0
      }
    );

    observer.observe(this.addToBagButton);
  }
}

if (!window.customElements.get('sticky-atc')) {
  window.customElements.define('sticky-atc', StickyATC);
}
