import { Splide } from "@splidejs/splide";

class MobileFadeCarousel extends HTMLElement {
  constructor() {
    super();

    this.slides = this.innerHTML;
    this.carousel = new Splide(this, {
      type: "fade",
      arrows: true,
      mediaQuery: 'min',
      pagination: false,
      perPage: 1,
      arrowPath:
        "M31 20.5 10.85 40 8 37.242 25.3 20.5 8 3.758 10.85 1l17.3 16.742L31 20.5Z",
      classes: {
        arrows: "splide__arrows ",
        arrow: "splide__arrow h-10 w-10 rounded-none opacity-100",
        prev: "-left-[2.25rem] splide__arrow--prev",
        next: "-right-[2.25rem] splide__arrow--next",
      },
      breakpoints: {
        1024: {
          destroy: true
        },
      },
    });
  }

  connectedCallback() {
   this.generateCarousel();
  }

  generateCarousel() {
    this.innerHTML = "";
    const list = document.createElement('div');
    const track = document.createElement('div');
    list.classList.add('splide__list', '!flex');
    track.classList.add('splide__track');
    list.innerHTML = this.slides;
    track.append(list);
    this.append(track);
    this.carousel.mount();
  }

}

if (!window.customElements.get("mobile-fade-carousel")) {
  window.customElements.define("mobile-fade-carousel", MobileFadeCarousel);
}
