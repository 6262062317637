class TabGroup extends HTMLElement {
  constructor() {
    super();

    this.isFristOpen = this.hasAttribute('first-open');
    this.contentContainer = this.querySelector('tab-content');
    this.buttons = this.querySelectorAll('button');
  }

  connectedCallback() {
      this.openFirstDetails();
      this.ensureSingleOpenDetails();
  }

  openFirstDetails() {
    if (this.isFristOpen) {
      this.contentContainer.firstElementChild.classList.remove("hidden")
      this.buttons[0].classList.add("!border-brand-secondary-200");
    }
  }

  ensureSingleOpenDetails() {
    const staticButtons = this.querySelectorAll('button');
    staticButtons.forEach((button) => {
      button.addEventListener('click', (event) => {
        const buttonID = event.target.getAttribute('content-type');
        event.target.classList.add('!border-brand-secondary-200')
        const content = this.contentContainer.querySelector(`[content-type="${buttonID}"]`);
        event.preventDefault();
        content.classList.remove("hidden");
        staticButtons.forEach((button) => {
          if(event.target !== button) {
            button.classList.remove('!border-brand-secondary-200');
          }
        })
        if (content) {
          this.contentContainer.children.forEach((el) => {
            if (el !== content && !el.classList.contains('hidden')) {
              el.classList.add('hidden');
            }
          });
        }
      });
    });
  }
}

if (!window.customElements.get('tab-group')) {
  window.customElements.define('tab-group', TabGroup);
}
