class ReviewHeader extends HTMLElement {
  constructor() {
    super();
    this.state = {
      position: 0,
      isMobile: false,
      lineHeight: 10
    }
  }
  setPosition() {
    const { lineHeight } = this.state;
    const windowWidth = window.innerWidth;
    this.state.isMobile = windowWidth < 1024;
    if (this.state.isMobile) {
      this.state.position = -10;
      this.style.left = `unset`;
      this.style.right = `${this.state.position}px`;
    } else {
      this.state.position = windowWidth / 2 - lineHeight;
      this.style.right = `unset`;
      this.style.left = `${this.state.position}px`;
    }
  }
  connectedCallback() {
    this.setPosition();
    window.addEventListener('resize', this.setPosition.bind(this));
  }
}

if (!window.customElements.get("review-header")) {
  window.customElements.define("review-header", ReviewHeader);
}
