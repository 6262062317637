import { subscribe } from '../helper/pubsub';
import { PUB_SUB_EVENTS } from '../helper/constants';

class FreeShippingBar extends HTMLElement {
  constructor() {
    super();
    this._threshold =
      parseFloat(this.getAttribute('threshold').replace(/[^0-9.]/g, '')) * 100;
    this.setAttribute('threshold', this._threshold);
    this._onCartUpdateListener = this._onCartUpdate.bind(this);
  }

  static get observedAttributes() {
    return ['threshold', 'total-price'];
  }

  connectedCallback() {
    this.cartUpdateUnsubscriber = subscribe(
      PUB_SUB_EVENTS.cartUpdate,
      this._onCartUpdateListener
    );
  }

  disconnectedCallback() {
    if (this.cartUpdateUnsubscriber) {
      this.cartUpdateUnsubscriber();
    }
  }

  get totalPrice() {
    return parseFloat(this.getAttribute('total-price'));
  }

  set totalPrice(value) {
    this.setAttribute('total-price', value);
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'total-price' && oldValue !== newValue) {
      this._updateMessage();
      this._updateProgressBar(true);
    }
  }

  _updateMessage() {
    const messageElement = this.querySelector('span');
    if (this.totalPrice >= this._threshold) {
      messageElement.innerHTML = this.getAttribute('reached-message');
    } else {
      const currencySymbol = this.getAttribute('data-currency-symbol') || '$';
      const remainingAmount = Math.ceil(
        (this._threshold - this.totalPrice) / 100
      );
      const replacement = `${currencySymbol}${remainingAmount}`;
      messageElement.innerHTML = this.getAttribute('unreached-message').replace(
        /\{\{[^}]+\}\}/g,
        replacement
      );
    }
  }

  _updateProgressBar(animate = false) {
    const progressBar = this.querySelector('progress-bar');
    if (progressBar) {
      if (animate) {
        progressBar.style.transition = 'none';
        progressBar.valueNow = 0;
        progressBar.offsetHeight; // Force reflow
        progressBar.style.transition = '';
      }
      progressBar.valueNow = this.totalPrice;
      progressBar.valueMax = this._threshold;
    }
  }

  _onCartUpdate(event) {
    const cart = event.cartData;
    if (!cart) {
      console.warn('No cart data received:', event);
      return;
    }

    const items = cart.items || [];

    const priceForItems = items
      .filter((item) => item && item.requires_shipping)
      .reduce((sum, item) => sum + (item.final_line_price || 0), 0);

    const cartDiscount = (cart.cart_level_discount_applications || []).reduce(
      (sum, discount) => sum + (discount.total_allocated_amount || 0),
      0
    );

    this.totalPrice = priceForItems - cartDiscount;
  }
}

if (!window.customElements.get('free-shipping-bar')) {
  window.customElements.define('free-shipping-bar', FreeShippingBar);
}
