class CollectionSeoBanner extends HTMLElement {
  constructor() {
    super();
    this.$dom = {
      readMore: this.querySelector(".js-collection-seo-banner-read-more"),
    };
    this.state = {
      active: false,
    };
  }

  connectedCallback() {
    this.$dom.readMore.addEventListener("click", this.onToggle.bind(this));
  }

  onToggle(event) {
    const { state } = this;
    event.preventDefault();
    event.stopPropagation();
    if (state.active) {
      this.classList.remove('is-active');
      this.state.active = false;
    } else {
      this.classList.add('is-active');
      this.state.active = true;
    }
  }
}

if (!window.customElements.get("collection-seo-banner")) {
  window.customElements.define("collection-seo-banner", CollectionSeoBanner);
}