/* Details set to open by default on desktop */
document.addEventListener('DOMContentLoaded', function () {
  const footer = document.querySelector('footer');
  const footerDetails = footer.querySelectorAll('details');

  var live_url = window.location.href;
  var result = live_url.includes('form_type=customer');
  var input_val = document.querySelector('[id*="NewsletterForm"]').value.length;

  if (result && input_val != 0) {
    const add_ele = document.createElement('p');
    add_ele.innerText = 'You have already subscribed!';
    document
      .querySelector('.newsletter-form__field-wrapper')
      .appendChild(add_ele);
    add_ele.classList.add(
      'text-brand-primary-100',
      'newsletter-form__message',
      'form__message',
      'mt-2'
    );
  }

  function adjustDetails() {
    if (window.innerWidth > 768) {
      footerDetails.forEach((detail) => detail.setAttribute('open', true)); // Ensure open on desktop
    } else {
      footerDetails.forEach((detail) => detail.removeAttribute('open', true)); // Allow normal behavior on mobile
    }
  }

  window.addEventListener('resize', adjustDetails);
  adjustDetails(); // Initial check on page load
});
