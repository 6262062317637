import { Splide } from "@splidejs/splide";

class FilteredFeatured extends HTMLElement {
  constructor() {
    super();

    this.dropdowns = this.querySelectorAll("dropdown-selector");
    this.button = this.querySelector('#Hairstory-MiniQuiz-Results');
    this.reset = this.querySelector('button[type="reset"]');
    this.collectionUrl = this.getAttribute("collection-url");
    this.productCarousel = this.querySelector("#filtered-product-grid");
    this.isLoading = false;
    this.emailSignup = this.querySelector('#optional-email-signup');
    this.isOptionalEmail = this.emailSignup !== null;

    // Check for stored results immediately
    const storedResults = sessionStorage.getItem('miniQuizResults');
    if (storedResults) {
      const htmlMarkup = document.createElement('div');
      htmlMarkup.innerHTML = storedResults;
      setTimeout(() => {
        this.displayResults(htmlMarkup);
        // Show email form if results are showing
        if (this.emailSignup) {
          this.emailSignup.classList.remove('hidden');
          sessionStorage.setItem('emailFormVisible', 'true');
        }

        // Handle hash removal and scrolling after content is ready
        if (window.location.hash === '#MiniQuizNewsletter') {
          window.history.replaceState('', document.title, window.location.pathname + window.location.search);
          const storedScrollPosition = sessionStorage.getItem('miniQuizScrollPosition');
          if (storedScrollPosition) {
            window.scrollTo({
              top: parseInt(storedScrollPosition)
            });
            sessionStorage.removeItem('miniQuizScrollPosition');
          }
        }
      }, 0);
    }

    // Check if email form was visible
    const emailFormVisible = sessionStorage.getItem('emailFormVisible');
    if (emailFormVisible && this.emailSignup) {
      this.emailSignup.classList.remove('hidden');
    }

    this.carousel = new Splide(this.productCarousel, {
      arrows: true,
      pagination: false,
      perPage: 4,
      gap: "60px",
      arrowPath:
        "M31 20.5 10.85 40 8 37.242 25.3 20.5 8 3.758 10.85 1l17.3 16.742L31 20.5Z",
      classes: {
        arrows: "splide__arrows ",
        arrow: "splide__arrow h-10 w-10 rounded-none opacity-100 top-1/3",
        prev: "-left-4 splide__arrow--prev",
        next: "-right-4 splide__arrow--next",
      },
      breakpoints: {
        1024: {
          pagination: true,
          perPage: 1,
          classes: {
            pagination:
              "splide__pagination filter-carousel__pagination -bottom-10",
            page: "filter-carousel-page",
          },
        },
      },
    });
  }

  connectedCallback() {
    if (this.button) {
      this.button.addEventListener("click", this.onFilterSubmit.bind(this));
    }
    if (this.reset) {
      this.reset.addEventListener("click", this.onResetSubmit.bind(this));
    }
  }

  onResetSubmit() {
    this.productCarousel.innerHTML = "";
    
    //swap buttons
    this.button.classList.toggle("hidden");
    this.reset.classList.toggle("hidden");

    //reset carousel
    this.productCarousel.classList.add("opacity-0", "hidden");
    this.productCarousel.classList.remove("opacity-100");

    // Hide email signup and clear its visibility state
    if (this.emailSignup) {
      this.emailSignup.classList.add('hidden');
      sessionStorage.removeItem('emailFormVisible');
    }

    for (let dropdown of this.dropdowns) {
      const select = dropdown.querySelector("select");
      select.selectedIndex = -1;
      var event = new Event("change");
      dropdown.dispatchEvent(event);
    }
  }

  async onFilterSubmit() {
    let filtersToSubmit = [];
    for (let dropdown of this.dropdowns) {
      const value = dropdown.querySelector("select").value;
      if (value != "") {
        filtersToSubmit.push(value);
        continue;
      }
    }

    if (!this.isLoading) {
      let url = this.collectionUrl + "?" + filtersToSubmit.join("&");
      let initialButton = this.button.textContent;
      try {
        this.isLoading = true;
        this.button.textContent = "Loading Results";
        let response = await fetch(url);
        if (!response.ok) throw new Error(response.status);
        let text = await response.text();

        let htmlMarkup = new DOMParser()
          .parseFromString(text, "text/html")
          .querySelector(".js-product-grid");

        // Store the results
        sessionStorage.setItem('miniQuizResults', htmlMarkup.innerHTML);
        sessionStorage.setItem('miniQuizUrl', url);
        sessionStorage.setItem('miniQuizScrollPosition', window.scrollY);
        
        // Show results
        this.displayResults(htmlMarkup);
        
        // Show email form with results
        if (this.emailSignup) {
          this.emailSignup.classList.remove('hidden');
          sessionStorage.setItem('emailFormVisible', 'true');
        }

      } catch (err) {
        console.error(err);
      }

      this.button.textContent = initialButton;
      this.isLoading = false;
    }
  }

  displayResults(htmlMarkup) {
    // Clear existing content first
    this.productCarousel.innerHTML = '';
    
    if (htmlMarkup.childElementCount > 1) {
      // Recreate the full Splide structure
      const arrows = document.createElement("div");
      arrows.classList.add("splide__arrows", "splide__arrows--ltr");
      arrows.innerHTML = `
        <button class="splide__arrow h-10 w-10 rounded-none opacity-100 top-1/3 -left-4 splide__arrow--prev" type="button" aria-label="Previous slide">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40" focusable="false">
            <path d="M31 20.5 10.85 40 8 37.242 25.3 20.5 8 3.758 10.85 1l17.3 16.742L31 20.5Z"></path>
          </svg>
        </button>
        <button class="splide__arrow h-10 w-10 rounded-none opacity-100 top-1/3 -right-4 splide__arrow--next" type="button" aria-label="Next slide">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40" focusable="false">
            <path d="M31 20.5 10.85 40 8 37.242 25.3 20.5 8 3.758 10.85 1l17.3 16.742L31 20.5Z"></path>
          </svg>
        </button>
      `;
      
      const track = document.createElement("div");
      track.classList.add("splide__track");
      track.setAttribute("aria-live", "polite");
      track.setAttribute("aria-atomic", "true");
      
      const list = document.createElement("div");
      list.classList.add("splide__list");
      list.setAttribute("role", "presentation");
      list.innerHTML = htmlMarkup.innerHTML;
      
      track.append(list);
      this.productCarousel.append(arrows);
      this.productCarousel.append(track);
      
      // Add necessary Splide classes to container
      this.productCarousel.classList.add(
        "splide", 
        "is-initialized", 
        "splide--slide", 
        "splide--ltr", 
        "splide--draggable", 
        "is-active"
      );

      // Destroy existing carousel if it exists
      if (this.carousel) {
        this.carousel.destroy();
      }

      // Reinitialize Splide
      this.carousel = new Splide(this.productCarousel, {
        arrows: true,
        pagination: false,
        perPage: 4,
        gap: "60px",
        arrowPath:
          "M31 20.5 10.85 40 8 37.242 25.3 20.5 8 3.758 10.85 1l17.3 16.742L31 20.5Z",
        classes: {
          arrows: "splide__arrows ",
          arrow: "splide__arrow h-10 w-10 rounded-none opacity-100 top-1/3",
          prev: "-left-4 splide__arrow--prev",
          next: "-right-4 splide__arrow--next",
        },
        breakpoints: {
          1024: {
            pagination: true,
            perPage: 1,
            classes: {
              pagination:
                "splide__pagination filter-carousel__pagination -bottom-10",
              page: "filter-carousel-page",
            },
          },
        },
      }).mount();

    } else {
      const container = document.createElement("div");
      container.classList.add(
        "grid",
        "lg:grid-cols-4",
        "gap-[60px]",
        "grid-cols-1"
      );
      container.innerHTML = htmlMarkup.innerHTML;
      this.productCarousel.append(container);
    }

    this.productCarousel.classList.remove("hidden");
    setTimeout(() => {
      this.productCarousel.classList.remove("opacity-0");
      this.productCarousel.classList.add("opacity-100");
    }, 100);

    this.button.classList.toggle("hidden");
    this.reset.classList.toggle("hidden");
  }
}

if (!window.customElements.get("filtered-featured")) {
  window.customElements.define("filtered-featured", FilteredFeatured);
}
