import { Splide } from "@splidejs/splide";

// JavaScript code for the custom web component
class AnnouncementSlider extends HTMLElement {
    constructor() {
      super();
    }
  
    connectedCallback() {
     new Splide(this, {
        type       : 'loop',
        height     : '100%',
        width      : '100%',
        perPage    : 1,
        pagination : false,
        arrows     : false,
        autoplay   : true,
        interval   : 10000,
        direction  : "ttb",
      }).mount();
    }
  
}
  
  customElements.define('announcement-slider', AnnouncementSlider);
  