import { Splide } from "@splidejs/splide";

class BlogImageCarousel extends HTMLElement {
  constructor() {
    super();

    this.slides = this.innerHTML;
    this.carousel = new Splide(this, {
      arrows: true,
      pagination: true,
      perPage: 1,
      arrowPath:
        "M31 20.5 10.85 40 8 37.242 25.3 20.5 8 3.758 10.85 1l17.3 16.742L31 20.5Z",
      classes: {
        arrows: "splide__arrows absolute w-full top-[40%]",
        arrow: "splide__arrow h-10 w-10 rounded-none opacity-100",
        prev: "-left-[2.25rem] splide__arrow--prev",
        next: "-right-[2.25rem] splide__arrow--next",
        pagination: "splide__pagination  -bottom-10",
        page: "filter-carousel-page"
      },
    });
  }

  connectedCallback() {
   this.generateCarousel();
  }

  generateCarousel() {
    this.innerHTML = "";
    const list = document.createElement("div");
    const track = document.createElement("div");
    const pagination = document.createElement("ul");
    list.classList.add("splide__list");
    track.classList.add("splide__track");
    pagination.classList.add("splide__pagination", 'relative', 'filter-carousel__pagination', "bottom-0", "mt-12", "lg:mt-14");
    list.innerHTML = this.slides;
    track.append(list);
    this.append(track);
    this.append(pagination);
    this.carousel.mount();
  }

}

if (!window.customElements.get("blog-image-carousel")) {
  window.customElements.define("blog-image-carousel", BlogImageCarousel);
}
