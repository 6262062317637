class ProductEditorialFaq extends HTMLElement {
  constructor() {
    super();
    this.$dom = {
      summary: Array.from(this.querySelectorAll("summary")),
    };
    this.state = {
      open: null,
    };
  }

  connectedCallback() {
    this.$dom.summary.forEach((summary) => {
      summary.addEventListener("click", this.onToggle.bind(this));
    });
    this.setInitialState();
  }

  setInitialState() {
    const open = this.$dom.summary.find((summary) => summary.parentNode.hasAttribute("open"));
    this.state.open = open || null;
  }

  onToggle(event) {
    const { state } = this;
    const { target } = event;
    const parent = target.parentNode;
    event.preventDefault();
    event.stopPropagation();
    if (state.open && state.open !== target) {
      state.open.parentNode.removeAttribute("open");
    }
    if (state.open === target) {
      parent.removeAttribute("open");
      this.state.open = null;
    } else {
      parent.setAttribute("open", "");
      this.state.open = target;
    }
  }
}

if (!window.customElements.get("product-editorial-faq")) {
  window.customElements.define("product-editorial-faq", ProductEditorialFaq);
}
