class StarRating extends HTMLElement {
  constructor() {
    super();
    this.state = {
      rating: 0,
      starWidth: 12,
      whiteSpace: 3.7
    };
  }
  displayRating() {
    const { rating, starWidth, whiteSpace } = this.state;
    const width = starWidth * rating + (whiteSpace * Math.round(rating));
    return (this.style.clip = `rect(0px,${width}px, 20px, 0px)`);
  }
  connectedCallback() {
    this.state.rating = parseFloat(this.dataset.rating);
    this.displayRating();
  }
}

if (!window.customElements.get("star-rating")) {
  window.customElements.define("star-rating", StarRating);
}



