export const ON_CHANGE_DEBOUNCE_TIMER = 300;

export const PUB_SUB_EVENTS = {
  cartUpdate: 'cart-update',
  quantityUpdate: 'quantity-update',
  optionValueSelectionChange: 'option-value-selection-change',
  variantChange: 'variant-change',
  cartError: 'cart-error'
};

export function headerHeight() {
  const header = document.querySelector(
    '.header-group-wrapper'
  );

  if (!header) return;

  const headerChildren = header.children;
  const announcementHeight = headerChildren.length > 0 ? headerChildren[0].clientHeight : 0;

  let height = 80;
  if (header && header.clientHeight > 0) {
    height = header.clientHeight;
  }

  document.documentElement.style.setProperty('--header-height', height + 'px');
  document.documentElement.style.setProperty('--announcement-height', announcementHeight + 'px');

  return height;
}

// Run the function initially and on window resize
window.addEventListener('resize', headerHeight);
headerHeight();

// Create a MutationObserver to detect changes in the header
const header = document.querySelector('.header-group-wrapper');

if (header) {
  const observer = new MutationObserver(() => {
    headerHeight();
  });

  // Start observing the header for changes in child elements or subtree
  observer.observe(header, {
    childList: true,    // Observe child element additions/removals
    subtree: true,      // Observe all descendants
    attributes: true,   // Observe attribute changes on the header
    characterData: false // Don't observe changes to text nodes
  });
}
