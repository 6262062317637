class RechargeElement extends HTMLElement {
  constructor() {
    super();

    this.addToBagTextElement = null;
    this.oneTimePurchaseInput = null;
    this.otpPriceText = null;
    this.productData = null;
    this.productId = null;
    this.productVariantsWithMetafields = null;
    this.rechargeSelectElement = null;
    this.selectedVariant = null;
    this.shouldRenderBulletPoints = null;
    this.subscriptionPerksElement = null;
    this.subscriptionPriceText = null;
    this.subscriptionPurchaseInput = null;
  }

  connectedCallback() {
    this.parseDataAttributes();
    this.waitForAndInitReChargeWidget();
    this.waitForRechargeSelect();
    this.relocateSubscriptionPriceAndCleanup();
    this.addRechargeEventListeners();
  }

  disconnectedCallback() {
    this.removeRechargeEventListeners();
  }

  /**
   * @description Function that is to bind event listeners to the DOM
   */

  addRechargeEventListeners() {
    const waitForElements = setInterval(() => {
      const oneTimePurchaseInput = this.querySelector('[data-radio-onetime]');
      const subscriptionPurchaseInput = this.querySelector(
        '[data-radio-subsave]'
      );

      if (oneTimePurchaseInput && subscriptionPurchaseInput) {
        this.oneTimePurchaseInput = oneTimePurchaseInput;
        this.subscriptionPurchaseInput = subscriptionPurchaseInput;

        this.oneTimePurchaseInput.addEventListener(
          'click',
          this.onOneTimePurchaseClick
        );
        this.subscriptionPurchaseInput.addEventListener(
          'click',
          this.onSubscriptionPurchaseClick
        );

        clearInterval(waitForElements);
      }
    }, 100);
  }
  /**
   * @description Function that is initially called to add variables to the class state from the web component data attributes
   */
  parseDataAttributes() {
    const productData = this.getAttribute('product-data');
    const productVariantsString = this.getAttribute('product-variants');
    const selectedVariant = this.getAttribute('selected-variant');
    this.shouldRenderBulletPoints = this.hasAttribute(
      'should-render-bullet-points'
    );

    if (productData) {
      this.productData = JSON.parse(productData);
      this.productId = this.productData.id;
    }

    if (productVariantsString) {
      const unescapedString = productVariantsString
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'");

      this.productVariantsWithMetafields = JSON.parse(unescapedString);

      // If the after being parsed, the object is still returning a string parse it once more, so we can access object properties
      if (typeof this.productVariantsWithMetafields === 'string') {
        this.productVariantsWithMetafields = JSON.parse(
          this.productVariantsWithMetafields
        );
      }
    }

    if (selectedVariant) {
      const unescapedVariantString = selectedVariant
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'");
      this.selectedVariant = JSON.parse(unescapedVariantString);
    }
  }

  /**
   * @description Function that is called when the OTP input is clicked
   */
  onOneTimePurchaseClick = () => {
    this.onRechargeOptionClick('otp');
  };

  /**
   * @description Function that is called when the subscription input is clicked
   */
  onSubscriptionPurchaseClick = () => {
    this.onRechargeOptionClick('subscription');
  };

  /**
   * @description Function that is called when a recharge option input is clicked. Primarly used to add bullet points, and make sure the select has an option mounted
   * @param {string} optionType The type of option that was clicked, either OTP, or subscription
   */
  onRechargeOptionClick(optionType) {
    const selectedOption =
      optionType === 'subscription'
        ? this.subscriptionPriceText
        : this.otpPriceText;

    if (!this.subscriptionPerksElement) {
      this.subscriptionPerksElement = this.querySelector(
        `[id="${this.productId}-subscription-perks"]`
      );
    }

    if (!this.addToBagTextElement) {
      this.addToBagTextElement.textContent = selectedOption.textContent.trim();
    }

    // Since the select element is removed from the dom when OTP is selected, we need to toggle the first child of the select back on when subscribe and save is clicked
    if (optionType === 'subscription') {
      this.rechargeSelectElement.children[0].selected = 'selected';

      if (this.shouldRenderBulletPoints && this.shouldRenderBulletPoints) {
        this.subscriptionPerksElement.classList.remove('hidden');
      }
    }

    if (optionType === 'otp' && this.shouldRenderBulletPoints) {
      this.querySelector(
        `[id="${this.productId}-subscription-perks"]`
      ).classList.add('hidden');
    }
  }

  /**
   * @description Waits for the recharge to be unmounted, and then removes all event listeners
   */
  removeRechargeEventListeners() {
    if (this.oneTimePurchaseInput) {
      this.oneTimePurchaseInput.removeEventListener(
        'click',
        this.onOneTimePurchaseClick
      );
    }
    if (this.subscriptionPurchaseInput) {
      this.subscriptionPurchaseInput.removeEventListener(
        'click',
        this.onSubscriptionPurchaseClick
      );
    }
  }

  /**
   * @description Waits for the data price subsave attribute to be loaded, and then moves it down to the add cart button, in additio to cleaning up any remnants from recharge
   */
  relocateSubscriptionPriceAndCleanup() {
    const waitForPriceElements = setInterval(() => {
      const subscriptionPriceElement = this.querySelector(
        '[data-price-subsave]'
      );
      const otpPriceElement = this.querySelector('[data-price-onetime]');
      const addToBagElement = this.querySelector('[data-price-id]');

      if (subscriptionPriceElement && otpPriceElement && addToBagElement) {
        // Store price texts
        this.subscriptionPriceText = subscriptionPriceElement;
        this.otpPriceText = otpPriceElement;

        // Replace add to bag text with subscription price
        this.addToBagTextElement = addToBagElement;
        this.addToBagTextElement.textContent =
          this.subscriptionPriceText.textContent.trim();

        // Hide unnecessary elements
        [
          '[data-label-discount]',
          '[data-price-subsave]',
          '[data-price-onetime]'
        ].forEach((selector) => {
          const element = this.querySelector(selector);
          if (element) {
            element.classList.add('hidden');
          }
        });

        clearInterval(waitForPriceElements);
      }
    }, 100);
  }

  /**
   * @description Waits for recharge to be loaded into the dom, and once it is, creates a custom widget based off the product ID
   */
  waitForAndInitReChargeWidget() {
    const pollForRecharge = setInterval(() => {
      if (window.ReChargeWidget) {
        const config = {
          productId: this.productId,
          injectionParent: `#subscription-selector-${this.productId}`
        };
        window.ReChargeWidget.createWidget(config);

        clearInterval(pollForRecharge);
      }
    }, 100);
  }

  /**
   * @description Waits for the recharge select element to be loaded into the dom, and then moves it down to below the variant selector
   */
  waitForRechargeSelect() {
    const checkForRechargeSelect = setInterval(() => {
      if (this.querySelector(`#selling_plan_${this.productId}`)) {
        this.rechargeSelectElement = this.querySelector(
          `#selling_plan_${this.productId}`
        );

        // we need to find the indexes of the options that will be present in the delivery dropdown. Once we have found them, we will remove all the other options from the select
        const allowedFrequencyIndexList = [];
        const rechargeSelectOptions = this.rechargeSelectElement.options;

        this.productVariantsWithMetafields[
          this.selectedVariant.title
        ].metafields.allowed_frequencies.forEach((deliveryFrequency) => {
          rechargeSelectOptions.forEach((option, idx) => {
            if (
              option.innerText.toLowerCase() ===
              `delivery every ${deliveryFrequency} weeks`
            ) {
              allowedFrequencyIndexList.push(idx);
            }
          });
        });

        // We loop through option in reverse to avoid issues with index changes while removing
        for (
          let i = this.rechargeSelectElement.options.length - 1;
          i >= 0;
          i--
        ) {
          if (!allowedFrequencyIndexList.includes(i)) {
            this.rechargeSelectElement.removeChild(rechargeSelectOptions[i]);
          }
        }

        // Select parent element of the select, to make sure we append the hidden label as well.
        const deliveryOptionsContainer = this.querySelector(
          `#delivery-options-${this.productId}`
        );
        deliveryOptionsContainer.append(
          this.rechargeSelectElement.parentElement
        );

        // remove the strong tag between the span and the subscribe and save text
        const subSaveSpan = this.querySelector('[data-label-text-subsave]');

        if (subSaveSpan.querySelector('strong')) {
          const strongElement = subSaveSpan.querySelector('strong');
          const strongText = strongElement.textContent.trim();
          const textNode = document.createTextNode(strongText);

          // Replace the strong element with the text node
          subSaveSpan.replaceChild(textNode, strongElement);
        }

        clearInterval(checkForRechargeSelect);
      }
    }, 100);
  }
}

if (!window.customElements.get('recharge-element')) {
  window.customElements.define('recharge-element', RechargeElement);
}
