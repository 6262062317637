import { Splide } from "@splidejs/splide";
import { Intersection } from '@splidejs/splide-extension-intersection';
// JavaScript code for the custom web component
class GlobalHeroBanner extends HTMLElement {
    constructor() {
      super();
      this.interval = this.getAttribute('interval');
    }

    connectedCallback() {
     const isAutoplay = Shopify.designMode ? false : true
     const splide = new Splide(this, {
        type       : 'fade',
        rewind     : true,
        autoplay   : isAutoplay,
        interval   : this.interval,
        perPage    : 1,
        perMove    : 1,
        arrows     : false,
        pagination : true,
        classes: {
            pagination: 'splide__pagination global-hero-carousel lg:px-12',
            page      : 'splide__pagination__page',
      },
      })

      splide.mount({ Intersection });

      document.addEventListener('shopify:section:load', (event) => {
        splide.refresh();
      })

      document.addEventListener('shopify:block:select', (event) => {
        const target = event.target;
        const isSlide = target.classList.contains('splide__slide');
        if(isSlide) {
           const index = target.getAttribute('aria-label').charAt(0)
           splide.go( parseInt(index) - 1 )
        }
      })


      /* disabled for now 

      splide.on( 'intersection', function ( entry ) {
        if ( entry.isIntersecting ) {
          // target video in slide & play
        } else {

        }
      } );
      */

      splide.on( 'active', function ( event ) {
        const slide = event.slide;
        const video = slide.querySelector('video');
        if(video != undefined) {
            video.play();
        }
      } );

      splide.on( 'inactive', function ( event ) {
        const slide = event.slide;
        const video = slide.querySelector('video');
        if(video != undefined) {
            video.pause();
        }
      } );
    }

}

if (!window.customElements.get("hero-carousel")) {
  window.customElements.define("hero-carousel", GlobalHeroBanner);
}

