class DropdownSelectorMiniQuiz extends HTMLElement {
  constructor() {
    super();

    this.button = this.querySelector('button');
    this.dropdown = this.querySelector('.dropdown');
    this.collectionUrl = this.getAttribute('collection-url');
    this.label = this.button.querySelector('div').textContent;
    this.isKeyboardNavigation = false; // Track keyboard navigation
  }

  connectedCallback() {
    this.fetchFilterOptions();
  }

  value() {
    return this.dropdown.querySelector('select').value;
  }

  toggleDropdown() {
    //toggles classes
    this.dropdown.classList.toggle('!opacity-0');
    this.dropdown.classList.toggle('hidden');
    this.updateState();
  }

  closeDropdown() {
    this.dropdown.classList.add('!opacity-0');
    this.dropdown.classList.add('hidden');
    this.updateState();
  }

  onDropdownChange(event) {
    //selectedOption[0] assumes only one option can be selected from the menu
    let activeLabel = '';
    if (event.target.selectedOptions) {
      activeLabel = event.target.selectedOptions[0].innerHTML;
    } else {
      activeLabel = this.label;
    }
    this.dropdown.querySelector('#active-selection>div').textContent =
      activeLabel;
    const suffix =
      event.target.id == 'hair-type'
        ? 'hair'
        : event.target.id == 'product-type'
          ? 'products'
          : '';
    this.button.querySelector('div').textContent = activeLabel + suffix;

    //close dropdown after change made
    if (!this.isKeyboardNavigation) {
      this.dropdown.classList.add('!opacity-0');
      this.dropdown.classList.add('hidden');
    }

    this.updateState();
  }

  updateState() {
    // forces focus on the select when clicking on the menu & blurs when clicking off.
    if (this.dropdown.classList.contains('hidden')) {
      this.dropdown.querySelector('select').blur();
    } else {
      this.dropdown.querySelector('select').focus();
    }

    this.isKeyboardNavigation = false; // Reset after state update
  }

  handleKeyboardNavigation(event) {
    if (event instanceof KeyboardEvent) {
      this.isKeyboardNavigation = true;
      // if dropdown is open, collapse if pressing 'Enter' or 'Tab' key
      if (
        !this.dropdown.classList.contains('hidden') &&
        (event.key === 'Enter' || event.key === 'Tab')
      ) {
        this.closeDropdown();
      }
    }
  }

  async fetchFilterOptions() {
    //get collection filter options
    let response = await fetch(this.collectionUrl);
    if (!response.ok) throw new Error(response.status);
    let text = await response.text();

    // find the filter related to this dropdown
    let htmlMarkup = new DOMParser()
      .parseFromString(text, 'text/html')
      .querySelector(`label[for="${this.dropdown.id}"]`).innerHTML;

    //create an active selection div
    const activeSelection = document.createElement('div');
    activeSelection.innerHTML = this.button.innerHTML;
    activeSelection.classList.add(
      'text-brand-primary-100',
      'font-semibold',
      'flex',
      'justify-between',
      'items-center',
      'pb-3'
    );
    activeSelection
      .querySelector('span')
      .classList.remove('lg:w-6', 'w-3', 'rotate-180');
    activeSelection
      .querySelector('span')
      .classList.add('lg:w-4', 'w-3', 'rotate-180');
    activeSelection.id = 'active-selection';
    this.dropdown.prepend(activeSelection);

    //create a container for the filters to append to
    const selectionContainer = document.createElement('div');
    selectionContainer.classList.add('flex', 'flex-col', 'gap-3', 'pb-5');
    this.dropdown.append(selectionContainer);

    // after this point the new dom takes effect.
    selectionContainer.innerHTML = htmlMarkup;

    //create and add event listeners
    const clickListeners = [['click', this.toggleDropdown.bind(this)]];
    clickListeners.forEach(([event, listener]) => {
      this.button.addEventListener(event, listener);
      activeSelection.addEventListener(event, listener);
    });

    const blurListeners = [['blur', this.closeDropdown.bind(this)]];

    blurListeners.forEach(([event, listener]) => {
      selectionContainer
        .querySelector('select')
        .addEventListener(event, listener);
    });

    selectionContainer
      .querySelector('select')
      .addEventListener('keydown', this.handleKeyboardNavigation.bind(this));

    this.addEventListener('change', this.onDropdownChange);

    this.updateState();
  }
}

if (!window.customElements.get('dropdown-selector')) {
  window.customElements.define('dropdown-selector', DropdownSelectorMiniQuiz);
}

class DropdownSelectorShopBy extends HTMLElement {
  constructor() {
    super();

    this.button = this.querySelector('button');
    this.dropdown = this.querySelector('.dropdown');
    this.label = this.button.querySelector('div').textContent;
    this.isKeyboardNavigation = false; // Track keyboard navigation
  }

  connectedCallback() {
    this.fetchFilterOptions();
  }

  value() {
    return this.dropdown.querySelector('select').value;
  }

  toggleDropdown() {
    //toggles classes
    this.dropdown.classList.toggle('!opacity-0');
    this.dropdown.classList.toggle('hidden');
    this.updateState();
  }

  onDropdownChange(event) {
    //selectedOption[0] assumes only one option can be selected from the menu

    let activeLabel = '';
    if (event.target.selectedOptions) {
      activeLabel = event.target.selectedOptions[0].innerHTML;
    } else {
      activeLabel = this.label;
    }
    this.dropdown.querySelector('#active-selection>div').textContent =
      activeLabel;
    this.button.querySelector('div').textContent = activeLabel;

    if (event.target.selectedOptions) {
      activeLabel = event.target.selectedOptions[0].innerHTML;
    } else {
      activeLabel = this.label;
    }
    this.dropdown.querySelector('#active-selection>div').textContent =
      activeLabel;
    this.button.querySelector('div').textContent = activeLabel;

    //hide current slider & show active slider.
    const carousels = this.closest('section').querySelectorAll(
      'featured-collection-carousel'
    );
    carousels.forEach((carousel) => {
      const filter = carousel.dataset.filter;
      if (!carousel.classList.contains('sr-only')) {
        carousel.classList.toggle('sr-only');
      } else if (event.target.value === filter) {
        carousel.classList.toggle('sr-only');
      }
    });

    //close dropdown after change made
    if (!this.isKeyboardNavigation) {
      this.dropdown.classList.add('!opacity-0');
      this.dropdown.classList.add('hidden');
    }

    this.updateState();
  }

  updateState() {
    // forces focus on the select when clicking on the menu & blurs when clicking off.
    if (this.dropdown.classList.contains('hidden')) {
      this.dropdown.querySelector('select').blur();
    } else {
      this.dropdown.querySelector('select').focus();
    }

    //Disable option that is already selected.
    const select = this.dropdown.querySelector('select');
    const options = select.querySelectorAll('option');

    options.forEach((option) => (option.disabled = false));
    select.options[select.selectedIndex].disabled = true;
  }

  closeDropdown() {
    this.dropdown.classList.add('!opacity-0');
    this.dropdown.classList.add('hidden');
    this.updateState();
  }

  handleKeyboardNavigation(event) {
    if (event instanceof KeyboardEvent) {
      this.isKeyboardNavigation = true;
      // if dropdown is open, collapse if pressing 'Enter' or 'Tab' key
      if (
        !this.dropdown.classList.contains('hidden') &&
        (event.key === 'Enter' || event.key === 'Tab')
      ) {
        this.closeDropdown();
      }
    }
  }

  async fetchFilterOptions() {
    //create an active selection div
    const activeSelection = document.createElement('button');
    activeSelection.innerHTML = this.button.innerHTML;
    activeSelection.classList.add(
      'text-brand-primary-100',
      'font-semibold',
      'flex',
      'justify-between',
      'items-center',
      'pb-3'
    );
    activeSelection.querySelector('span').classList.remove('w-8', 'rotate-180');
    activeSelection
      .querySelector('span')
      .classList.add('lg:w-4', 'w-3', 'rotate-180');
    activeSelection.id = 'active-selection';

    //create a container for the filters to append to
    const selectionContainer = document.createElement('div');
    selectionContainer.classList.add('flex', 'flex-col', 'gap-3', 'pb-5');

    // after this point the new dom takes effect.
    const htmlMarkup = this.dropdown.innerHTML;
    this.dropdown.innerHTML = '';
    this.dropdown.append(selectionContainer);
    selectionContainer.innerHTML = htmlMarkup;
    this.dropdown.prepend(activeSelection);

    //create and add event listeners
    const clickListeners = [['click', this.toggleDropdown.bind(this)]];
    clickListeners.forEach(([event, listener]) => {
      this.button.addEventListener(event, listener);
      activeSelection.addEventListener(event, listener);
    });

    const blurListeners = [['blur', this.closeDropdown.bind(this)]];

    blurListeners.forEach(([event, listener]) => {
      selectionContainer
        .querySelector('select')
        .addEventListener(event, listener);
    });

    selectionContainer
      .querySelector('select')
      .addEventListener('keydown', this.handleKeyboardNavigation.bind(this));

    this.addEventListener('change', this.onDropdownChange);

    this.updateState();
  }
}

if (!window.customElements.get('dropdown-selector-type')) {
  window.customElements.define(
    'dropdown-selector-type',
    DropdownSelectorShopBy
  );
}
