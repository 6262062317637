import { Splide } from "@splidejs/splide";

class ProductEditorialBeforeAfter extends HTMLElement {
  constructor() {
    super();
    this.$dom = {
      mobileCarousel: this.querySelector(".js-mobile-carousel"),
      desktopCarousel: this.querySelector(".js-desktop-carousel"),
      thumbnailCarousel: this.querySelector(".js-thumbnail-carousel"),
      mobileCarouselNext: this.querySelector(".js-mobile-carousel-next"),
      mobileCarouselPrevious: this.querySelector(".js-mobile-carousel-previous"),
      mobileCarouselProgressBar: this.querySelector(".js-mobile-progress-bar"),
      desktopCarouselProgressBar: this.querySelector(".js-desktop-progress-bar"),
    };
    this.state = {
      activeClass: "is-active",
      syncing: false,
      mobileCarousel: null,
      mobileCarouselOptions: {
        arrows: false,
        pagination: false,
      },
      desktopCarousel: null,
      desktopCarouselOptions: {
        arrows: false,
        pagination: false,
      },
      thumbnailCarousel: null,
      thumbnailCarouselOptions: {
        fixedWidth: 96,
        arrows: false,
        rewind: true,
        pagination: false,
        isNavigation: true
      },
    };
  }

  connectedCallback() {
    if (this.$dom.mobileCarousel) this.initMobileCarousel();
    if (this.$dom.desktopCarousel) this.initDesktopCarousel();
    if (this.$dom.thumbnailCarousel) this.initThumbnailCarousel();
    this.syncCarousels();
  }

  initMobileCarousel() {
    if (this.$dom.mobileCarouselNext) {
      this.$dom.mobileCarouselNext.addEventListener('click', () => {
        this.state.mobileCarousel.go('+1');
      });
    }

    if (this.$dom.mobileCarouselPrevious) {
      this.$dom.mobileCarouselPrevious.addEventListener('click', () => {
        this.state.mobileCarousel.go('-1');
      });
    }

    this.state.mobileCarousel = new Splide(this.$dom.mobileCarousel, {
      ...this.state.mobileCarouselOptions,
    });

    this.state.mobileCarousel.on("mounted move", () => {
      const total = this.state.mobileCarousel.Components.Controller.getEnd() + 1;
      const width = `${100 / total}%`;
      const left = `${100 * this.state.mobileCarousel.index / total}%`;
      if (this.$dom.mobileCarouselProgressBar) {
        this.$dom.mobileCarouselProgressBar.style.width = width;
        this.$dom.mobileCarouselProgressBar.style.left = left;
      }
    });

    this.state.mobileCarousel.on("moved", (newIndex) => {
      const thumbnailIndex = Math.floor(newIndex / 2);
      if (!this.state.syncing) {
        this.state.syncing = true;
        this.state.thumbnailCarousel.go(thumbnailIndex);
        this.state.syncing = false;
      }
    });

    this.state.mobileCarousel.mount();
  }

  initDesktopCarousel() {
    this.state.desktopCarousel = new Splide(this.$dom.desktopCarousel, {
      ...this.state.desktopCarouselOptions,
    });

    this.state.desktopCarousel.on("mounted move", () => {
      const total = this.state.desktopCarousel.Components.Controller.getEnd() + 1;
      const width = `${100 / total}%`;
      const left = `${100 * this.state.desktopCarousel.index / total}%`;
      if (this.$dom.desktopCarouselProgressBar) {
        this.$dom.desktopCarouselProgressBar.style.width = width;
        this.$dom.desktopCarouselProgressBar.style.left = left;
      }
    });

    this.state.desktopCarousel.mount();
  }

  initThumbnailCarousel() {
    this.state.thumbnailCarousel = new Splide(this.$dom.thumbnailCarousel, {
      ...this.state.thumbnailCarouselOptions,
    });

    this.state.thumbnailCarousel.on("moved", (newIndex) => {
      const mobileIndex = newIndex * 2;
      if (!this.state.syncing) {
        this.state.syncing = true;
        this.state.mobileCarousel.go(mobileIndex);
        this.state.syncing = false;
      }
    });

    this.state.thumbnailCarousel.mount();
  }

  syncCarousels() {
    if (this.state.desktopCarousel && this.state.thumbnailCarousel) {
      this.state.desktopCarousel.sync(this.state.thumbnailCarousel);
    }
  }
}

if (!window.customElements.get("product-editorial-before-after")) {
  window.customElements.define("product-editorial-before-after", ProductEditorialBeforeAfter);
}
