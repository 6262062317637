import { CartItems } from "./cart";

function isCartPage() {
  return window.location.pathname === '/cart';
}

class CartDrawer extends HTMLElement {
  constructor() {
    super();

    this.header = this.closest("header");
    this.details = this.querySelector("details");
    this.parentDetails = this.details.querySelectorAll("details");
    this.hideOnScroll(); // Hide the close button when scrolling within the cart drawer
  }

  connectedCallback() {
    this.details
      .querySelector("summary")
      .addEventListener("click", this.onToggle.bind(this));
    this.parentDetails.forEach((detail) => {
      detail
        .querySelector("summary")
        .addEventListener("click", this.onToggle.bind(this));
    });
  }

  hideOnScroll() {
    const scrollContainer = this.querySelector('cart-drawer-wrapper');
    const closeButton = this.querySelector('#cart-drawer-close');
    let lastScrollTop = 0;
    let ticking = false;
  
    if (scrollContainer && closeButton) {
      scrollContainer.addEventListener('scroll', () => {
        if (!ticking) {
          window.requestAnimationFrame(() => {
            const scrollTop = scrollContainer.scrollTop;
            if (scrollTop > lastScrollTop && scrollTop > 10) {
              // Scrolling down and past a threshold
              closeButton.classList.remove('group-open/drawer:visible');
            } else {
              // Scrolling up or near the top
              closeButton.classList.add('group-open/drawer:visible');
            }
            lastScrollTop = scrollTop;
            ticking = false;
          });
          ticking = true;
        }
      });
    } else {
      console.warn('ScrollContainer or closeButton not found in CartDrawer');
    }
  }

  drawerOpenAnimation(target, animationClass = ["animate-right-left-open"]) {
    const onAnimationEnd = (cb) =>
      target
        .querySelector("cart-drawer-wrapper")
        .addEventListener("animationend", cb, { once: true });

    const overlay = target
      .querySelector("summary")
      .querySelector("#drawer-overlay");

    requestAnimationFrame(() => {
      target
        .querySelector("cart-drawer-wrapper")
        .classList.add(...animationClass);
      if (overlay) overlay.classList.add("lg:!opacity-40");
    });

    onAnimationEnd(() => {
      target
        .querySelector("cart-drawer-wrapper")
        .classList.remove(...animationClass);
      if (overlay) overlay.classList.remove("lg:!opacity-40");
    });

    target.open = true;
  }

  drawerCloseAnimation(target, animationClass = ["animate-left-right-close"]) {
    const overlay = target
      .querySelector("summary")
      .querySelector("#drawer-overlay");

    const onAnimationEnd = (cb) =>
      target
        .querySelector("cart-drawer-wrapper")
        .addEventListener("animationend", cb, { once: true });

    target
      .querySelector("cart-drawer-wrapper")
      .classList.add(...animationClass);
    if (overlay) overlay.classList.add("lg:opacity-0");

    onAnimationEnd(() => {
      target.open = false;
      target
        .querySelector("cart-drawer-wrapper")
        .classList.remove(...animationClass);
      target.querySelectorAll("details").forEach((child) => {
        child.open = false;
      });
      if (overlay) overlay.classList.remove("lg:opacity-0");
    });
  }

  onToggle(event) {
    //on closure of target details, close all child details & sibling details
    event.preventDefault();
    const target = event.target.closest("details");
    const isOpen = target.hasAttribute("open");

    const openCls = ["animate-right-left-open"];
    const closeCls = ["animate-left-right-close"];

    if (isOpen) {
      this.drawerCloseAnimation(target, closeCls);
    } else {
      this.drawerOpenAnimation(target, openCls);
    }
  }

  renderContents(parsedState) {
    this.querySelector('.drawer__inner').classList.contains('is-empty') &&
      this.querySelector('.drawer__inner').classList.remove('is-empty');
    this.productId = parsedState.id;
    this.getSectionsToRender().forEach((section) => {
      const sectionElement = section.selector
        ? document.querySelector(section.selector)
        : document.getElementById(section.id);
      sectionElement.innerHTML = this.getSectionInnerHTML(parsedState.sections[section.id], section.selector);
    });
  }

  getSectionInnerHTML(html, selector = '.shopify-section') {
    return new DOMParser().parseFromString(html, 'text/html').querySelector(selector).innerHTML;
  }

  getSectionsToRender() {
    return [
      {
        id: 'cart-drawer',
        selector: '#CartDrawer',
      },
      {
        id: 'cart-icon-bubble',
      },
    ];
  }

  getSectionDOM(html, selector = '.shopify-section') {
    return new DOMParser().parseFromString(html, 'text/html').querySelector(selector);
  }

  setActiveElement(element) {
    this.activeElement = element;
  }
}
if (!isCartPage()) {
  customElements.define('cart-drawer', CartDrawer);
}

class CartDrawerItems extends CartItems {
  getSectionsToRender() {
    return [
      {
        id: 'CartDrawer',
        section: 'cart-drawer',
        selector: '.drawer__inner',
      },
      {
        id: 'cart-icon-bubble',
        section: 'cart-icon-bubble',
        selector: '.shopify-section',
      },
    ];
  }
}

if (!isCartPage()) {
  customElements.define('cart-drawer-items', CartDrawerItems);
}