import { Splide } from '@splidejs/splide';

class PressCarousel extends HTMLElement {
  constructor() {
    super();
    this.perPage = this.getAttribute('perPage');
  }

  connectedCallback() {
    this.generateCarousel();
  }

  generateCarousel() {
    const mainSlider = document.createElement('div');
    mainSlider.classList.add('splide');
    const list = document.createElement('div');
    const track = document.createElement('div');
    list.classList.add('splide__list', 'lg:!flex', 'lg:gap-16');
    track.classList.add('splide__track');
    list.innerHTML = this.innerHTML;
    this.innerHTML = '';

    track.append(list);
    mainSlider.append(track);
    this.append(mainSlider);

    const main = new Splide(mainSlider, {
      type: 'slide',
      pagination: true,
      arrows: false,
      perPage: this.perPage,
      gap: '20px',
      padding: { right: '20%' },
      mediaQuery: 'min',
      classes: {
        pagination:
          'splide__pagination global-hero-carousel lg:px-12 !w-full !top-auto !justify-center',
        page: 'splide__pagination__page'
      },
      breakpoints: {
        1024: {
          destroy: true
        }
      }
    });

    main.mount();
  }
}

if (!window.customElements.get('press-carousel')) {
  window.customElements.define('press-carousel', PressCarousel);
}
