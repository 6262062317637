class RecommendedProducts extends HTMLElement {
  observer = undefined;

  constructor() {
    super();
  }

  connectedCallback() {
    //this.initializeRecommendations(this.dataset.productId, this.dataset.url);
  }

  initializeRecommendations(productId) {
    this.observer?.unobserve(this);
    this.observer = new IntersectionObserver(
      (entries, observer) => {
        if (!entries[0].isIntersecting) return;
        observer.unobserve(this);
        this.loadRecommendations(productId);
      },
      { rootMargin: '0px 0px 0px 0px' }
    );
    this.observer.observe(this);
  }

  async loadRecommendations(productId, url) {
    try {
      const response = await fetch(`${url}&product_id=${productId}&section_id=${this.dataset.sectionId}`);
      const text = await response.text();
      const html = document.createElement('div');
      html.innerHTML = text;
      const recommendations = html.querySelector('recommended-products');
      if (recommendations?.innerHTML.trim().length) {
        this.innerHTML = recommendations.innerHTML;
      }
    } catch (error) {
      console.error(error);
    }
  }
}

customElements.define('recommended-products', RecommendedProducts);