// TODO @{lbarrick}: remove this once we move off of digioh

function Delegate(root) {
  this.listenerMap = [{}, {}];
  if (root) {
    this.root(root);
  }
  this.handle = Delegate.prototype.handle.bind(this);
  this._removedListeners = [];
}
Delegate.prototype.root = function (root) {
  const listenerMap = this.listenerMap;
  let eventType;
  if (this.rootElement) {
    for (eventType in listenerMap[1]) {
      if (listenerMap[1].hasOwnProperty(eventType)) {
        this.rootElement.removeEventListener(eventType, this.handle, true);
      }
    }
    for (eventType in listenerMap[0]) {
      if (listenerMap[0].hasOwnProperty(eventType)) {
        this.rootElement.removeEventListener(eventType, this.handle, false);
      }
    }
  }
  if (!root || !root.addEventListener) {
    if (this.rootElement) {
      delete this.rootElement;
    }
    return this;
  }
  this.rootElement = root;
  for (eventType in listenerMap[1]) {
    if (listenerMap[1].hasOwnProperty(eventType)) {
      this.rootElement.addEventListener(eventType, this.handle, true);
    }
  }
  for (eventType in listenerMap[0]) {
    if (listenerMap[0].hasOwnProperty(eventType)) {
      this.rootElement.addEventListener(eventType, this.handle, false);
    }
  }
  return this;
};
Delegate.prototype.captureForType = function (eventType) {
  return (
    ['blur', 'error', 'focus', 'load', 'resize', 'scroll'].indexOf(
      eventType
    ) !== -1
  );
};
Delegate.prototype.on = function (eventType, selector, handler, useCapture) {
  let root;
  let listenerMap;
  let matcher;
  let matcherParam;
  if (!eventType) {
    throw new TypeError('Invalid event type: ' + eventType);
  }
  if (typeof selector === 'function') {
    useCapture = handler;
    handler = selector;
    selector = null;
  }
  if (useCapture === void 0) {
    useCapture = this.captureForType(eventType);
  }
  if (typeof handler !== 'function') {
    throw new TypeError('Handler must be a type of Function');
  }
  root = this.rootElement;
  listenerMap = this.listenerMap[useCapture ? 1 : 0];
  if (!listenerMap[eventType]) {
    if (root) {
      root.addEventListener(eventType, this.handle, useCapture);
    }
    listenerMap[eventType] = [];
  }
  if (!selector) {
    matcherParam = null;
    matcher = matchesRoot.bind(this);
  } else if (/^[a-z]+$/i.test(selector)) {
    matcherParam = selector;
    matcher = matchesTag;
  } else if (/^#[a-z0-9\-_]+$/i.test(selector)) {
    matcherParam = selector.slice(1);
    matcher = matchesId;
  } else {
    matcherParam = selector;
    matcher = Element.prototype.matches;
  }
  listenerMap[eventType].push({
    selector,
    handler,
    matcher,
    matcherParam
  });
  return this;
};
Delegate.prototype.off = function (eventType, selector, handler, useCapture) {
  let i;
  let listener;
  let listenerMap;
  let listenerList;
  let singleEventType;
  if (typeof selector === 'function') {
    useCapture = handler;
    handler = selector;
    selector = null;
  }
  if (useCapture === void 0) {
    this.off(eventType, selector, handler, true);
    this.off(eventType, selector, handler, false);
    return this;
  }
  listenerMap = this.listenerMap[useCapture ? 1 : 0];
  if (!eventType) {
    for (singleEventType in listenerMap) {
      if (listenerMap.hasOwnProperty(singleEventType)) {
        this.off(singleEventType, selector, handler);
      }
    }
    return this;
  }
  listenerList = listenerMap[eventType];
  if (!listenerList || !listenerList.length) {
    return this;
  }
  for (i = listenerList.length - 1; i >= 0; i--) {
    listener = listenerList[i];
    if (
      (!selector || selector === listener.selector) &&
      (!handler || handler === listener.handler)
    ) {
      this._removedListeners.push(listener);
      listenerList.splice(i, 1);
    }
  }
  if (!listenerList.length) {
    delete listenerMap[eventType];
    if (this.rootElement) {
      this.rootElement.removeEventListener(eventType, this.handle, useCapture);
    }
  }
  return this;
};
Delegate.prototype.handle = function (event) {
  let i;
  let l;
  const type = event.type;
  let root;
  let phase;
  let listener;
  let returned;
  let listenerList = [];
  let target;
  const eventIgnore = 'ftLabsDelegateIgnore';
  if (event[eventIgnore] === true) {
    return;
  }
  target = event.target;
  if (target.nodeType === 3) {
    target = target.parentNode;
  }
  if (target.correspondingUseElement) {
    target = target.correspondingUseElement;
  }
  root = this.rootElement;
  phase = event.eventPhase || (event.target !== event.currentTarget ? 3 : 2);
  switch (phase) {
    case 1:
      listenerList = this.listenerMap[1][type];
      break;
    case 2:
      if (this.listenerMap[0] && this.listenerMap[0][type]) {
        listenerList = listenerList.concat(this.listenerMap[0][type]);
      }
      if (this.listenerMap[1] && this.listenerMap[1][type]) {
        listenerList = listenerList.concat(this.listenerMap[1][type]);
      }
      break;
    case 3:
      listenerList = this.listenerMap[0][type];
      break;
  }
  let toFire = [];
  l = listenerList.length;
  while (target && l) {
    for (i = 0; i < l; i++) {
      listener = listenerList[i];
      if (!listener) {
        break;
      }
      if (
        target.tagName &&
        ['button', 'input', 'select', 'textarea'].indexOf(
          target.tagName.toLowerCase()
        ) > -1 &&
        target.hasAttribute('disabled')
      ) {
        toFire = [];
      } else if (listener.matcher.call(target, listener.matcherParam, target)) {
        toFire.push([event, target, listener]);
      }
    }
    if (target === root) {
      break;
    }
    l = listenerList.length;
    target = target.parentElement || target.parentNode;
    if (target instanceof HTMLDocument) {
      break;
    }
  }
  let ret;
  for (i = 0; i < toFire.length; i++) {
    if (this._removedListeners.indexOf(toFire[i][2]) > -1) {
      continue;
    }
    returned = this.fire.apply(this, toFire[i]);
    if (returned === false) {
      toFire[i][0][eventIgnore] = true;
      toFire[i][0].preventDefault();
      ret = false;
      break;
    }
  }
  return ret;
};
Delegate.prototype.fire = function (event, target, listener) {
  return listener.handler.call(target, event, target);
};
function matchesTag(tagName, element) {
  return tagName.toLowerCase() === element.tagName.toLowerCase();
}
function matchesRoot(selector, element) {
  if (this.rootElement === window) {
    return (
      element === document ||
      element === document.documentElement ||
      element === window
    );
  }
  return this.rootElement === element;
}
function matchesId(id, element) {
  return id === element.id;
}
Delegate.prototype.destroy = function () {
  this.off();
  this.root();
};
var main_default = Delegate;

// js/custom-element/custom-html-element.js
var CustomHTMLElement = class extends HTMLElement {
  constructor() {
    super();
    this._hasSectionReloaded = false;
    if (Shopify.designMode) {
      this.rootDelegate.on('shopify:section:select', (event) => {
        const parentSection = this.closest('.shopify-section');
        if (event.target === parentSection && event.detail.load) {
          this._hasSectionReloaded = true;
        }
      });
    }
  }
  get rootDelegate() {
    return (this._rootDelegate =
      this._rootDelegate || new main_default(document.documentElement));
  }
  get delegate() {
    return (this._delegate = this._delegate || new main_default(this));
  }
  showLoadingBar() {
    triggerEvent(document.documentElement, 'theme:loading:start');
  }
  hideLoadingBar() {
    triggerEvent(document.documentElement, 'theme:loading:end');
  }
  untilVisible(
    intersectionObserverOptions = { rootMargin: '30px 0px', threshold: 0 }
  ) {
    const onBecameVisible = () => {
      this.classList.add('became-visible');
      this.style.opacity = '1';
    };
    return new Promise((resolve) => {
      if (window.IntersectionObserver) {
        this.intersectionObserver = new IntersectionObserver((event) => {
          if (event[0].isIntersecting) {
            this.intersectionObserver.disconnect();
            requestAnimationFrame(() => {
              resolve();
              onBecameVisible();
            });
          }
        }, intersectionObserverOptions);
        this.intersectionObserver.observe(this);
      } else {
        resolve();
        onBecameVisible();
      }
    });
  }
  disconnectedCallback() {
    var _a;
    this.delegate.destroy();
    this.rootDelegate.destroy();
    (_a = this.intersectionObserver) == null ? void 0 : _a.disconnect();
    delete this._delegate;
    delete this._rootDelegate;
  }
};

var ProductItem = class extends CustomHTMLElement {
  connectedCallback() {
    this.primaryImageList = Array.from(
      this.querySelectorAll('.product-item__primary-image')
    );
    this.delegate.on(
      'change',
      '.product-item-meta__swatch-list .color-swatch__radio',
      this._onColorSwatchChanged.bind(this)
    );
    this.delegate.on(
      'mouseenter',
      '.product-item-meta__swatch-list .color-swatch__item',
      this._onColorSwatchHovered.bind(this),
      true
    );
  }

  async _onColorSwatchChanged(event, target) {
    Array.from(this.querySelectorAll(`[href*="/products"]`)).forEach((link) => {
      let url;
      if (link.tagName === 'A') {
        url = new URL(link.href);
      } else {
        url = new URL(
          link.getAttribute('href'),
          `https://${window.themeVariables.routes.host}`
        );
      }
      url.searchParams.set('variant', target.getAttribute('data-variant-id'));
      link.setAttribute('href', url.toString());
    });
    if (target.hasAttribute('data-variant-featured-media')) {
      const newImage = this.primaryImageList.find(
        (image) =>
          image.getAttribute('data-media-id') ===
          target.getAttribute('data-variant-featured-media')
      );
      newImage.setAttribute('loading', 'eager');
      const onImageLoaded = newImage.complete
        ? Promise.resolve()
        : new Promise((resolve) => (newImage.onload = resolve));
      await onImageLoaded;
      newImage.removeAttribute('hidden');
      let properties = {};
      if (
        Array.from(newImage.parentElement.classList).some((item) =>
          [
            'aspect-ratio--short',
            'aspect-ratio--tall',
            'aspect-ratio--square'
          ].includes(item)
        )
      ) {
        properties = [
          {
            clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
            transform: 'translate(calc(-50% - 20px), -50%)',
            zIndex: 1,
            offset: 0
          },
          {
            clipPath: 'polygon(0 0, 20% 0, 5% 100%, 0 100%)',
            transform: 'translate(calc(-50% - 20px), -50%)',
            zIndex: 1,
            offset: 0.3
          },
          {
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            offset: 1
          }
        ];
      } else {
        properties = [
          {
            clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
            transform: 'translateX(-20px)',
            zIndex: 1,
            offset: 0
          },
          {
            clipPath: 'polygon(0 0, 20% 0, 5% 100%, 0 100%)',
            transform: 'translateX(-20px)',
            zIndex: 1,
            offset: 0.3
          },
          {
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
            transform: 'translateX(0px)',
            zIndex: 1,
            offset: 1
          }
        ];
      }
      await newImage.animate(properties, {
        duration: 500,
        easing: 'ease-in-out'
      }).finished;
      this.primaryImageList
        .filter(
          (image) =>
            image.classList.contains('product-item__primary-image') &&
            image !== newImage
        )
        .forEach((image) => image.setAttribute('hidden', ''));
    }
  }

  _onColorSwatchHovered(event, target) {
    const input = target.previousElementSibling;
    if (input.hasAttribute('data-variant-featured-media')) {
      const newImage = this.primaryImageList.find(
        (image) =>
          image.getAttribute('data-media-id') ===
          input.getAttribute('data-variant-featured-media')
      );
      newImage.setAttribute('loading', 'eager');
    }
  }
};

window.customElements.define('product-item', ProductItem);
