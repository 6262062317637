import { Splide } from '@splidejs/splide';

class ReviewCarousel extends HTMLElement {
  constructor() {
    super();

    this.slides = this.innerHTML;
    this.carousel = new Splide(this, {
      arrows: true,
      pagination: false,
      autoWidth: true,
      gap: 20,
      perPage: 1,
      arrowPath:
        'M31 20.5 10.85 40 8 37.242 25.3 20.5 8 3.758 10.85 1l17.3 16.742L31 20.5Z',
      classes: {
        arrows:
          'splide__arrows absolute w-12 lg:top-[100px] top-[60px] lg:right-[150px] right-[32px]',
        arrow: 'splide__arrow h-3 w-3 rounded-none bg-transparent opacity-100',
        prev: 'left-0 splide__arrow--prev',
        next: 'right-0 splide__arrow--next'
      }
    });
  }

  connectedCallback() {
    this.generateCarousel();
  }

  generateCarousel() {
    this.innerHTML = '';
    const list = document.createElement('div');
    const track = document.createElement('div');

    list.classList.add('splide__list', 'h-full');
    track.classList.add(
      'splide__track',
      'overflow-hidden',
      'max-w-[484px]',
      'h-full'
    );

    list.innerHTML = this.slides;
    track.append(list);
    this.append(track);
    this.carousel.mount();
  }
}

if (!window.customElements.get('review-carousel')) {
  window.customElements.define('review-carousel', ReviewCarousel);
}
