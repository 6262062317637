class FilterDrawer extends HTMLElement {
  constructor() {
    super();
    this.details = this.querySelector('details');
    this.parentDetails = this.details.querySelectorAll('details');
  }

  connectedCallback() {
    this.details
      .querySelector('summary')
      .addEventListener('click', this.onToggle.bind(this));
  }

  drawerOpenAnimation(target, animationClass = ['animate-right-left-open']) {
    const onAnimationEnd = (cb) =>
      target
        .querySelector('filter-drawer-wrapper')
        .addEventListener('animationend', cb, { once: true });

    const overlay = target
      .querySelector('summary')
      .querySelector('#drawer-overlay');

    requestAnimationFrame(() => {
      target
        .querySelector('filter-drawer-wrapper')
        .classList.add(...animationClass);
      if (overlay) overlay.classList.add('lg:!opacity-40');
    });

    onAnimationEnd(() => {
      target
        .querySelector('filter-drawer-wrapper')
        .classList.remove(...animationClass);
      if (overlay) overlay.classList.remove('lg:!opacity-40');
    });

    target.open = true;
  }

  drawerCloseAnimation(target, animationClass = ['animate-left-right-close']) {
    const overlay = target
      .querySelector('summary')
      .querySelector('#drawer-overlay');

    const onAnimationEnd = (cb) =>
      target
        .querySelector('filter-drawer-wrapper')
        .addEventListener('animationend', cb, { once: true });

    target
      .querySelector('filter-drawer-wrapper')
      .classList.add(...animationClass);
    if (overlay) overlay.classList.add('lg:opacity-0');

    onAnimationEnd(() => {
      target.open = false;
      target
        .querySelector('filter-drawer-wrapper')
        .classList.remove(...animationClass);
      target.querySelectorAll('details').forEach((child) => {
        child.open = false;
      });
      if (overlay) overlay.classList.remove('lg:opacity-0');
    });
  }

  onToggle(event) {
    //on closeure of target details, close all child details & sibling details
    event.preventDefault();
    const target = event.target.closest('details');
    const isOpen = target.hasAttribute('open');

    const openCls = ['animate-right-left-open'];
    const closeCls = ['animate-left-right-close'];

    if (isOpen) {
      this.drawerCloseAnimation(target, closeCls);
    } else {
      this.drawerOpenAnimation(target, openCls);
    }
  }
}

if (!window.customElements.get('filter-drawer')) {
  window.customElements.define('filter-drawer', FilterDrawer);
}

document.addEventListener("DOMContentLoaded", function () {
  const filterDrawer = document.querySelector("filter-drawer");
  const filterDetails = filterDrawer.querySelectorAll("details");

  function adjustDetails() {
    if (window.innerWidth > 768 && filterDrawer) {
      filterDetails.forEach((detail) => detail.setAttribute("open", true)); // Ensure open on desktop
    } else {
      filterDetails.forEach((detail) => detail.removeAttribute("open", true)); // Allow normal behavior on mobile
    }
  }

  window.addEventListener("resize", adjustDetails);
  adjustDetails(); // Initial check on page load
});
