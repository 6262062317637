class ProgressBar extends HTMLElement {
  constructor() {
    super();
  }

  static get observedAttributes() {
    return ["aria-valuenow", "aria-valuemax"];
  }

  connectedCallback() {
    this._calculateProgressBar();
  }

  get progress() {
    return Math.min(1, this.getAttribute("aria-valuenow") / this.getAttribute("aria-valuemax"));
  }

  set valueMax(value) {
    this.setAttribute("aria-valuemax", value);
  }

  set valueNow(value) {
    this.setAttribute("aria-valuenow", value);
  }

  attributeChangedCallback() {
    this._calculateProgressBar();
  }

  _calculateProgressBar() {
    this.style.setProperty("--progress", this.progress.toFixed(2));
  }
}

if (!window.customElements.get("progress-bar")) {
  window.customElements.define("progress-bar", ProgressBar);
}