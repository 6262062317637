import { Splide } from "@splidejs/splide";

class ContentAccordion extends HTMLElement {
  constructor() {
    super();
    this.dom = {
      btns: false,
      slider: false,
      content: false,
    };
    this.state = {
      slider: false,
      active: 0,
      background: false,
      cssVariable: false,
      options: {
        type: 'fade',
        autoHeight: true,
        perPage: 1,
        perMove: 1,
        drag: false,
        arrows: false,
        pagination: false,
        easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
      }
    };
    this.handleClick = this.selectIndex.bind(this);
  }
  selectIndex(e) {
    const { state } = this;
    const element = e.target;
    if (!element) return;

    const index = parseInt(element.dataset.index);
    const background = element.dataset.background;
    state.background = background;
    state.active = index;
    this.handleContent();
    this.moveSlider();
  }
  moveSlider() {
    const { active, slider } = this.state;
    return slider.go(active);
  }
  handleContent() {
    const { dom, state } = this;

    dom.btns.forEach((btn) => {
      const index = parseInt(btn.dataset.index);
      if (index === state.active) {
        btn.classList.add('accordion-btn--active');
      } else {
        btn.classList.remove('accordion-btn--active');
      }
    });

    dom.content.forEach((content) => {
      const index = parseInt(content.dataset.index);
      if (index === state.active) {
        content.classList.add('accordion-content--active');
      } else {
        content.classList.remove('accordion-content--active')
      }
    });

    this.changeBackground();
  }
  changeBackground() {
    const { cssVariable, background } = this.state;

    const style = document.documentElement.style;
    style.setProperty(cssVariable, background);
  }
  connectedCallback() {
    const { dom, state } = this;
    dom.slider = this.querySelector('.splide');
    dom.btns = this.querySelectorAll('.js-accordion-btn');
    dom.column = this.querySelector('.js-accordion-column');
    dom.content = this.querySelectorAll('.js-accordion-content');
    state.cssVariable = this.dataset.background;
    state.slider = new Splide(dom.slider, state.options);
    state.slider.mount();
    dom.btns.forEach((btn) => btn.addEventListener('click', this.handleClick));
    dom.btns[0].click();
  }
}

if (!window.customElements.get("accordion-wrapper")) {
  window.customElements.define("accordion-wrapper", ContentAccordion);
}
