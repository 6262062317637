class SimpleFaqGroup extends HTMLElement {
  constructor() {
    super();
    this.details = this.querySelectorAll('details');
  }

  connectedCallback() {
    this.details.forEach(detail => {
      detail.addEventListener('toggle', this.handleToggle.bind(this));
    });
  }

  handleToggle(event) {
    if (event.target.open) {
      this.details.forEach(detail => {
        if (detail !== event.target && detail.open) {
          detail.open = false;
        }
      });
    }
  }
}

if (!customElements.get('simple-faq-group')) {
  customElements.define('simple-faq-group', SimpleFaqGroup);
}
