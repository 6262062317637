import { Splide } from "@splidejs/splide";

class ProductEditorialStyleGallery extends HTMLElement {
  constructor() {
    super();
    this.$dom = {
      carousel: this.querySelector(".js-carousel"),
      thumbnailCarousel: this.querySelector(".js-thumbnail-carousel"),
      carouselNext: this.querySelector(".js-carousel-next"),
      carouselPrevious: this.querySelector(".js-carousel-previous"),
      carouselProgressBar: this.querySelector(".js-progress-bar"),
    };
    this.state = {
      activeClass: "is-active",
      carousel: null,
      carouselOptions: {
        arrows: false,
        pagination: false,
      },
      thumbnailCarousel: null,
      thumbnailCarouselOptions: {
        fixedWidth: 96,
        arrows: false,
        rewind: true,
        pagination: false,
        isNavigation: true
      },
    };
  }

  connectedCallback() {
    if (this.$dom.carousel) this.initCarousel();
    if (this.$dom.thumbnailCarousel) this.initThumbnailCarousel();
    this.syncCarousels();
  }

  initCarousel() {
    if (this.$dom.carouselNext) {
      this.$dom.carouselNext.addEventListener('click', () => {
        this.state.carousel.go('+1');
      });
    }

    if (this.$dom.carouselPrevious) {
      this.$dom.carouselPrevious.addEventListener('click', () => {
        this.state.carousel.go('-1');
      });
    }

    this.state.carousel = new Splide(this.$dom.carousel, {
      ...this.state.carouselOptions,
    });

    this.state.carousel.on("mounted move", () => {
      const total = this.state.carousel.Components.Controller.getEnd() + 1;
      const width = `${100 / total}%`;
      const left = `${100 * this.state.carousel.index / total}%`;
      if (this.$dom.carouselProgressBar) {
        this.$dom.carouselProgressBar.style.width = width;
        this.$dom.carouselProgressBar.style.left = left;
      }
    });

    this.state.carousel.mount();
  }

  initThumbnailCarousel() {
    this.state.thumbnailCarousel = new Splide(this.$dom.thumbnailCarousel, {
      ...this.state.thumbnailCarouselOptions,
    });

    this.state.thumbnailCarousel.mount();
  }

  syncCarousels() {
    if (this.state.carousel && this.state.thumbnailCarousel) {
      this.state.carousel.sync(this.state.thumbnailCarousel);
    }
  }
}

if (!window.customElements.get("product-editorial-style-gallery")) {
  window.customElements.define("product-editorial-style-gallery", ProductEditorialStyleGallery);
}
